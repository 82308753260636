import React from "react";
import "../../styles/core.css";
import processing from "../../icons/processing.png";
import finished from "../../icons/finished.png";
import failed from "../../icons/failed.png";
import deleteIcon from "../../icons/delete.svg";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import history from "../history";
import {
  jobTimeConvertToStr,
  jobTimeDeltaConvertToStr,
} from "../../utils/time";
import { BACKENDURL } from "../../contants/backendSettings";
import axios from "axios";

export default function TaskCard(props) {
  let data = props.data;
  const [dateState, setDateState] = React.useState(new Date());
  React.useEffect(() => {
    const interval = setInterval(() => setDateState(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);

  const submitTime = jobTimeConvertToStr(data.jobTime);
  let costTime = jobTimeDeltaConvertToStr(
    data.jobTimeDelta,
    dateState,
    data.jobTime
  );

  const projectName = data.jobProjectName;
  const status = data.jobStatus;
  const model = data.jobType
    .replace("ssaa", "Amino Acid Long Sequence Model")
    .replace("lsaa", "Amino Acid Short Sequnece Model")
    .replace("ssnt", "Nucleotide Long Sequence Model")
    .replace("lsnt", "Nucleotide Short Sequence Model");
  const inputFile = data.jobInputFile;
  let buttonStatus;
  let iconStatus;
  let interruptDisplay;
  let deleteDisplay;
  let disabled = true;
  if (data.jobStatus == "Finished") {
    buttonStatus = "primary-button";
    iconStatus = finished;
    interruptDisplay = "none";
    deleteDisplay = "auto";
    disabled = false;
  } else if (data.jobStatus == "Failure") {
    interruptDisplay = "none";
    buttonStatus = "disabled-button";
    iconStatus = processing;
    deleteDisplay = "auto";
    disabled = true;
  } else {
    buttonStatus = "disabled-button";
    iconStatus = processing;
    interruptDisplay = "auto";
    deleteDisplay = "none";
    disabled = true;
  }
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const onDelete = (e) => {
    const cancelJob = async () => {
      if (props.jobToken) {
        await axios
          .post(`${BACKENDURL}/deeplearning/cancel`, {
            token: props.jobToken,
          })
          .then((res) => {
            // console.log(res.data);
            if ("message" in res.data) {
              setOpen(false);
              setOpen1(false);
              props.onTaskDelete()
            }
            if ("error" in res.data) {
              setOpen(true);
              setOpen1(true);
            }
          });
      }
    };
    cancelJob();
  };

  const handleInterruptOpen = () => {
    setOpen(true);
  };
  const handleInterruptClose = () => {
    setOpen(false);
  };

  const handleDeleteOpen = () => {
    setOpen1(true);
  };
  const handleDeleteClose = () => {
    setOpen1(false);
  };

  const handleOnClick = (e) => {
    // console.log("(TaskCard.js) handleOnClick", data);
    e.preventDefault();
    if (props.jobToken) {
      // console.log("This is TaskCard Info: ", props.jobToken);
      history.push(`/result/${props.jobToken}`);
    } else {
    }
  };

  return (
    <div className="task-card">
      <div className="time-container">
        <p>
          Submitted at <span>{submitTime}</span>
        </p>
        <p
          style={{
            textAlign: "right",
          }}
        >
          <span>{costTime}</span> cost
        </p>
      </div>
      <div className="model-status-container">
        <h3>Name: {projectName}</h3>
        <div className="status-container">
          <img src={iconStatus} alt="" />
          <p>{status}</p>
        </div>
      </div>
      <div className="id-file-coantiner">
        <p>
          Model: <span>{model}</span>
        </p>
        {inputFile !== "NA" ? (
          <p>
            Input File: <span>{inputFile}</span>
          </p>
        ) : null}
      </div>
      <div className="result-button-container">
        <button
          className="dangerous-button"
          id="task-card-interrupt"
          style={{
            marginTop: "0",
            display: interruptDisplay,
          }}
          onClick={handleInterruptOpen}
        >
          Cancel
        </button>
        <button
          style={{
            marginTop: "0",
            marginRight: "20px",
            display: deleteDisplay,
          }}
          onClick={handleDeleteOpen}
        >
          <img src={deleteIcon} alt="delete" />
        </button>
        <button
          className={buttonStatus}
          style={{
            margin: "0",
          }}
          onClick={handleOnClick}
          disabled={disabled}
        >
          Result
        </button>
      </div>
      <Dialog
        open={open}
        onClose={handleInterruptClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure to cancel this task?"}
        </DialogTitle>
        <DialogContent>
          <p>
            Cancellation is irreversible and you will lose all records of this
            task.
          </p>
        </DialogContent>
        <DialogActions>
          <button
            className="secondary-button"
            onClick={handleInterruptClose}
            style={{
              marginRight: "10px",
            }}
          >
            Back
          </button>
          <button className="primary-button" onClick={onDelete} autoFocus>
            Confirm
          </button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open1}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure to delete the task?"}
        </DialogTitle>
        <DialogContent>
          <p>
            Deletion is irreversible and you will lose all records of this task.
          </p>
        </DialogContent>
        <DialogActions>
          <button
            className="secondary-button"
            onClick={handleDeleteClose}
            style={{
              marginRight: "10px",
            }}
          >
            Back
          </button>
          <button className="primary-button" onClick={onDelete} autoFocus>
            Delete
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
