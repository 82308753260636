import React from "react";
import { Link } from "react-router-dom";

export default function LoginPrompt() {
  return (
    <div
      className="login-prompt"
      style={{
        paddingBottom: "40px",
        borderBottom: "1px lightGrey solid",
      }}
    >
      <h3>Free login to save your prediction!</h3>
      <p>
        There is no requirement to create an account to use any part of ARGNet,
        however you will gain the benefit of seeing a list of your previous
        modelling projects here.
      </p>
      <div
        className="login-button-container"
        style={{
          display: "flex",
          marginTop: "20px",
        }}
      >
        <Link to={`/login`}>
          <button
            className="primary-button"
            style={{
              marginRight: "20px",
            }}
          >
            Login
          </button>
        </Link>
        <Link to={`/register`}>
          <button className="secondary-button">Register</button>
        </Link>
      </div>
    </div>
  );
}
