import React from "react";
import noTask from "../../img/noTask.png";

export default function NoTask() {
  return (
    <div>
      <p
        style={{
          marginTop: "40px",
        }}
      >
        You haven't submitted any prediction task.
        <a href="/prediction">Go Predict</a>
      </p>

      <img
        src={noTask}
        alt="noTask"
        style={{
          marginTop: "40px",
        }}
      />
    </div>
  );
}
