import React, { useEffect, useState } from "react";
import LocationDropdown from "../FormDropdown1";
import Grid from "@material-ui/core/Grid";
import "./FormSignup.css";
import { useForm, Controller } from "react-hook-form";
import getCountriesList from "../../utils/getCountriesList";
import { Link } from "react-router-dom";
import history from "../history";
import * as DisplayedMessages from "../DisplayedMessages";
import { BACKENDURL } from "../../contants/backendSettings";
import { CircularProgress } from "@material-ui/core";

const FormSignup = ({ submitForm }) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    setError,
    reset,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const [locationList, setLocationList] = useState(null);
  const [unclearState, setUnclearState] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  setValue("location", selectedCountry);
  // console.log(selectedCountry);
  useEffect(() => {
    const getFetchedCountries = async () => {
      try {
        const response = await getCountriesList();
        // console.log(response);
        setLocationList(response.countries);
      } catch (err) {
        setLocationList([])
        // console.log(err);
      }
    };
    getFetchedCountries();
  }, []);

  const onSubmit = (data, e) => {
    const fetchUserData = async () => {
      try {
        setIsSubmitting(true)
        await fetch(`${BACKENDURL}/account/signup`, {
          headers: { "Content-Type": "application/json" },
          method: "POST",
          body: JSON.stringify({
            first_name: firstName,
            last_name: lastName,
            location: location.toUpperCase(),
            email: email,
            confirm_email: email2,
            password: password,
            confirm_password: password2,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            // console.log(res);
            setIsSubmitting(false)
            if ("error" in res) {
              if (res["error"] === "Email is already being used.") {
                reset({
                  ...getValues(),
                  email: "",
                  email2: "",
                  password: "",
                  password2: "",
                });
                setError("email", {
                  type: "existed",
                  message: DisplayedMessages.EMAIL_BEING_USED,
                });
                setError("email2", {
                  type: "existed",
                  message: DisplayedMessages.EMAIL_BEING_USED,
                });
                throw new Error(DisplayedMessages.EMAIL_BEING_USED);
              } else if (
                res["error"] === "Something went wrong when sending email."
              ) {
                throw new Error(DisplayedMessages.EMAIL_UNSEND);
              } else {
                throw new Error(DisplayedMessages.UNCLEAR_ERROR);
              }
            } else {
              history.push("/activation");
            }
          });
      } catch (err) {
        setIsSubmitting(false)
        // console.log(err.message);
        if (err.message === "Cannot read property 'toUpperCase' of undefined") {
          // console.log(DisplayedMessages.LOCATION_UNSELECTED);
          reset({ ...getValues(), password: "", password2: "" });
          setError("countryOption", {
            type: "notSelected",
            message: DisplayedMessages.LOCATION_UNSELECTED,
          });
        } else if (err.message === "Failed to fetch") {
          // console.log(DisplayedMessages.CONNECTION_ERROR);
          setUnclearState(true);
        } else {
          // console.log(err.message);
        }
      }
    };

    // e.target.reset();
    e.preventDefault();
    // console.log(data);

    const {
      firstName,
      lastName,
      email,
      email2,
      password,
      password2,
      location,
    } = data;

    if (email !== email2) {
      setError("email", {
        type: "matches",
        message: DisplayedMessages.EMAIL_MATCHES,
      });
      setError("email2", {
        type: "matches",
        message: DisplayedMessages.EMAIL_MATCHES,
      });
    }

    if (password !== password2) {
      setError("password", {
        type: "matches",
        message: DisplayedMessages.PASSWORD_MATCHES,
      });
      setError("password2", {
        type: "matches",
        message: DisplayedMessages.PASSWORD_MATCHES,
      });
    }

    if (email === email2 && password === password2) {
      fetchUserData();
    } else if (email !== email2) {
      // console.log(DisplayedMessages.EMAIL_MATCHES);
    } else if (password !== password2) {
      // console.log(DisplayedMessages.PASSWORD_MATCHES);
    } else {
      // console.log(DisplayedMessages.EMAIL_AND_PASSWORD_MATCHES);
    }
  };

  return (
    <div className="form-content-right1">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="form"
        id="sign-up-form"
      >
        <h1>REGISTER</h1>
        <Grid
          container
          sm={12}
          style={{
            textAlign: "left",
          }}
        >
          <Grid item md={6} sm={12} xs={12}>
            <div className="form-location">
              <label className="form-label"> Location </label>
              <Controller
                control={control}
                defaultValue=""
                render={({ value }) => {
                  return (
                    <LocationDropdown
                      countries={
                        locationList
                          ? locationList.map((data) => {
                              const code = data.code.toLowerCase();
                              return {
                                key: code,
                                value: code,
                                code: code,
                                label: data.country,
                              };
                            })
                          : [
                              // {
                              //   key: "af",
                              //   value: "af",
                              //   code: "af",
                              //   label: "Afghanistan",
                              // },
                            ]
                      }
                      rules={{ required: true }}
                      selectedCountry={setSelectedCountry}
                    />
                  );
                }}
              />
              {errors.countryOption && <p> {errors.countryOption} </p>}
            </div>
          </Grid>
          <Grid container md={6} sm="auto" xs="auto"></Grid>

          <Grid item md={6} sm={12} xs={12}>
            <div className="form-inputs1">
              <label className="form-label"> First name </label>
              <input
                className="form-input1"
                type="text"
                placeholder="Enter your first name"
                {...register("firstName", { required: true, maxLength: 64 })}
              />
              {errors.firstName?.type === "required" && (
                <span style={{ color: "#F87700" }}>{DisplayedMessages.REQUIRED}</span>
              )}
              {errors.firstName?.type === "maxLength" && (
                <span style={{ color: "#F87700" }}>
                  {DisplayedMessages.LENGTH_64}
                </span>
              )}
            </div>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <div className="form-inputs1">
              <label className="form-label"> Last Name </label>
              <input
                className="form-input1"
                type="text"
                placeholder="Enter your last name"
                {...register("lastName", { required: true, maxLength: 64 })}
              />
              {errors.lastName?.type === "required" && (
                <span style={{ color: "#F87700" }}>{DisplayedMessages.REQUIRED}</span>
              )}
              {errors.last?.type === "maxLength" && (
                <span style={{ color: "#F87700" }}>
                  {DisplayedMessages.LENGTH_64}
                </span>
              )}
            </div>
          </Grid>

          <Grid item md={6} sm={12} xs={12}>
            <div className="form-inputs1">
              <label className="form-label"> Email </label>
              <input
                className="form-input1"
                name="email"
                type="email"
                placeholder="Enter your email"
                {...register("email", {
                  required: true,
                  maxLength: 255,
                  pattern:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                })}
              />
              {errors.email?.type === "required" && (
                <span style={{ color: "#F87700" }}>{DisplayedMessages.REQUIRED}</span>
              )}
              {errors.email?.type === "pattern" && (
                <span style={{ color: "#F87700" }}>{DisplayedMessages.EMAIL_FORMAT}</span>
              )}
              {errors.email?.type === "existed" && (
                <span style={{ color: "#F87700" }}>{DisplayedMessages.EMAIL_BEING_USED}</span>
              )}
            </div>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <div className="form-inputs1">
              <label className="form-label"> Confirm Email </label>
              <input
                className="form-input1"
                type="email"
                placeholder="Enter your email again"
                {...register("email2", {
                  required: true,
                  maxLength: 255,
                  pattern:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                })}
              />
              {errors.email2?.type === "required" && (
                <span style={{ color: "#F87700" }}>{DisplayedMessages.REQUIRED}</span>
              )}
              {errors.email2?.type === "pattern" && (
                <span style={{ color: "#F87700" }}>{DisplayedMessages.EMAIL_FORMAT}</span>
              )}
              {errors.email2?.type === "matches" && (
                <span style={{ color: "#F87700" }}>{DisplayedMessages.EMAIL_MATCHES}</span>
              )}
            </div>
          </Grid>

          <Grid item md={6} sm={12} xs={12}>
            <div className="form-inputs1">
              <label className="form-label"> Password </label>
              <input
                className="form-input1"
                type="password"
                placeholder="Enter your password"
                {...register("password", {
                  required: true,
                  maxLength: 20,
                  minLength: 8,
                  validate: (value) => {
                    if (value.toLowerCase() === value) {
                      return false;
                    }
                    if (!/(?=.*[#?!@$%^&*-.])/.test(value)) {
                    // if (!/(?=.*[!@#$%^&*])/.test(value)) {
                      return false;
                    }
                    return true;
                  },
                })}
              />
              {errors.password?.type === "required" && (
                <span style={{ color: "#F87700" }}>{DisplayedMessages.REQUIRED}</span>
              )}
              {errors.password?.type === "maxLength" && (
                <span style={{ color: "#F87700" }}>
                  {DisplayedMessages.PASSWORD_ATMOST_20}
                </span>
              )}
              {errors.password?.type === "minLength" && (
                <span style={{ color: "#F87700" }}>
                  {DisplayedMessages.PASSWORD_ATLEAST_8}
                </span>
              )}
              {errors.password?.type === "validate" && (
                <span style={{ color: "#F87700" }}>
                  {DisplayedMessages.PASSWORD_COMBIN}
                </span>
              )}
            </div>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <div className="form-inputs1">
              <label className="form-label"> Confirm Password </label>
              <input
                className="form-input1"
                type="password"
                placeholder="Confirm your password"
                {...register("password2", {
                  required: true,
                  maxLength: 20,
                  minLength: 8,
                  validate: (value) => {
                    if (value.toLowerCase() === value) {
                      return false;
                    }
                    if (!/(?=.*[#?!@$%^&*-.])/.test(value)) {
                      // if (!/(?=.*[!@#$%^&*])/.test(value)) {
                        return false;
                      }
                    return true;
                  },
                })}
              />
              {errors.password2?.type === "required" && (
                <span style={{ color: "#F87700" }}>{DisplayedMessages.REQUIRED}</span>
              )}
              {errors.password2?.type === "maxLength" && (
                <span style={{ color: "#F87700" }}>
                  {DisplayedMessages.PASSWORD_ATMOST_20}
                </span>
              )}
              {errors.password2?.type === "minLength" && (
                <span style={{ color: "#F87700" }}>
                  {DisplayedMessages.PASSWORD_ATLEAST_8}
                </span>
              )}
              {errors.password2?.type === "validate" && (
                <span style={{ color: "#F87700" }}>
                  {DisplayedMessages.PASSWORD_COMBIN}
                </span>
              )}
              {errors.password2?.type === "matches" && (
                <span style={{ color: "#F87700" }}>{DisplayedMessages.PASSWORD_MATCHES}</span>
              )}
            </div>
          </Grid>

          <Grid item md={6} sm={12} xs={12}>
            <button
              className="form-input-btn1"
              type="submit"
              form="sign-up-form"
              disabled={isSubmitting}
            >
            {isSubmitting ? (
              <CircularProgress
                size={24}
                className="spinner"
                color="#c4c4c4"
                thickness={5}
              />
            ) : (
              "Sign Up"
            )}
            </button>
            <div className="turn-to-login">
              <span className="form-input-login">
                Already have an account ? <Link to={`/login`}> Login </Link>
              </span>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default FormSignup;
