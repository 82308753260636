import React from "react";
import styled from "styled-components";
import { useTable, useBlockLayout } from "react-table";
import { FixedSizeList } from "react-window";
import more from "../../icons/more.svg";
import ascInactiveIcon from "../../icons/ascInactive.svg";
import dascInactiveIcon from "../../icons/dascInactive.svg";
import filterInactiveIcon from "../../icons/filterInactive.svg";
import ascActiveIcon from "../../icons/ascActive.svg";
import dascActiveIcon from "../../icons/dascActive.svg";
import filterActiveIcon from "../../icons/filterActive.svg";
import deleteIcon from "../../icons/deleteIcon.svg";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { SortAsc, SortDasc, Filter, DeleteSort } from "./HandleJson";

const scrollbarWidth = () => {
  const scrollDiv = document.createElement("div");
  scrollDiv.setAttribute(
    "style",
    "width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;"
  );
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

const Styles = styled.div`
  .table {
    display: inline-block;
    border-spacing: 0;
    border-bottom: 1px solid lightGrey;
    width: 100%;

    .tr {
    }
    .tr-cell {
      width: 25%;
      background-color: #f5f5f5;
      display: flex;
      border-right: lightgrey solid 1px;
      border-bottom: lightgrey solid 1px;
      text-align: center;
    }
    .th {
      margin: 0;
      background-color: #f5f5f5;
      padding: 0.5rem;
      border-bottom: 1px solid lightGrey;
      border-right: 1px solid lightGrey;
    }
    .td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid lightGrey;
      border-right: 1px solid lightGrey;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
function ResultTable(d) {
  const [newData, setNewData] = React.useState(d.data);
  const [filterData, setFilterData] = React.useState(null);
  const [sorting, setSorting] = React.useState(null);
  const [filter, setFilter] = React.useState("");
  const [filterActive, setFilterActive] = React.useState(null);
  let dataRender = d.data ? d.data : [];
  let originalData = dataRender.slice();
  React.useEffect(() => {
    dataRender = newData;
    // console.log("sss");
  }, [sorting]);
  React.useEffect(() => {
    dataRender = filterData;
    // console.log(filterData);
  }, [filter]);
  function HeaderMenu({ data, label, column }) {
    const [filterDialogDisplay, setFilterDialogDislay] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
      setOpen(false);
      setFilterDialogDislay(false);
    };

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
      if (prevOpen.current && !open) {
        anchorRef.current.focus();
      }
      prevOpen.current = open;
    }, [open]);

    const onSortAsc = () => {
      setSorting(column + "asc");
      SortAsc(!filterData ? newData : filterData, column);
      // setOpen(false);
    };
    const onSortDasc = () => {
      setSorting(column + "dasc");
      SortDasc(!filterData ? newData : filterData, column);
      // setOpen(false);
    };

    const deleteSort = () => {
      setSorting(null);
      DeleteSort(!filterData ? newData : filterData);
      // setOpen(false);
    };

    const deleteFilter = () => {
      setFilter("");
      setFilterData(null);
      // setOpen(false);
    };
    function handleFilterDialogDislay() {
      setFilterDialogDislay(!filterDialogDisplay);
    }

    function FilterDialog() {
      const [value, setValue] = React.useState("");
      const onFilter = () => {
        setOpen(false);
        setFilterDialogDislay(false);
        setFilter(value);
        setFilterActive(value ? column : null);
        setFilterData(
          Filter(newData, value.toLowerCase(), column, originalData)
        );
      };

      return (
        <div className="filter-container">
          <div className="filter-form">
            <input
              className="filter-input"
              type="text"
              placeholder="Filter Value"
              value={value}
              onInput={(e) => setValue(e.target.value)}
            ></input>
            <button className="filter-button" onClick={onFilter}>
              Apply
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="header-menu-container">
        <p>{label}</p>
        <button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <img alt="more" src={more} />
        </button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal
          style={{
            zIndex: "10000",
            width: "200px",
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-end" ? "right top" : "right bottom",
              }}
            >
              <div className="header-menu-list-container">
                <ClickAwayListener onClickAway={handleClose}>
                  <div>
                    <div className="header-menu-list">
                      <div className="list-item">
                        <img
                          alt="asc"
                          src={ascInactiveIcon}
                          style={{
                            display:
                              sorting == column + "asc" ? "none" : "block",
                          }}
                        />
                        <img
                          alt="asc"
                          src={ascActiveIcon}
                          style={{
                            display:
                              sorting == column + "asc" ? "block" : "none",
                          }}
                        />
                        <button onClick={onSortAsc} className="text-button">
                          Sort by ASC
                        </button>
                        <button onClick={deleteSort}>
                          <img
                            alt="delete"
                            src={deleteIcon}
                            style={{
                              display:
                                sorting == column + "asc" ? "block" : "none",
                            }}
                          />
                        </button>
                      </div>
                      <div className="list-item">
                        <img
                          alt="dasc"
                          src={dascInactiveIcon}
                          style={{
                            display:
                              sorting == column + "dasc" ? "none" : "block",
                          }}
                        />
                        <img
                          alt="dasc"
                          src={dascActiveIcon}
                          style={{
                            display:
                              sorting == column + "dasc" ? "block" : "none",
                          }}
                        />
                        <button onClick={onSortDasc} className="text-button">
                          Sort by DASC
                        </button>
                        <button onClick={deleteSort}>
                          <img
                            alt="delete"
                            src={deleteIcon}
                            style={{
                              display:
                                sorting == column + "dasc" ? "block" : "none",
                            }}
                          />
                        </button>
                      </div>
                      <div className="list-item" id="filter-list-item">
                        <img
                          alt="filter"
                          src={filterInactiveIcon}
                          style={{
                            display: filterActive == column ? "none" : "block",
                          }}
                        />
                        <img
                          alt="filter"
                          src={filterActiveIcon}
                          style={{
                            display: filterActive == column ? "block" : "none",
                          }}
                        />
                        <button
                          onClick={handleFilterDialogDislay}
                          className="text-button"
                        >
                          Filter
                        </button>
                        <button onClick={deleteFilter}>
                          <img
                            alt="delete"
                            src={deleteIcon}
                            style={{
                              display:
                                filterActive == column ? "block" : "none",
                            }}
                          />
                        </button>
                      </div>
                    </div>
                    {filterDialogDisplay ? <FilterDialog /> : null}
                  </div>
                </ClickAwayListener>
              </div>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
  function Table({ columns, data }) {
    let height;
    if (data.length > 17) {
      height = 600;
    } else {
      height = data.length * 30 - 1;
    }
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      totalColumnsWidth,
      prepareRow,
    } = useTable(
      {
        columns,
        data,
      },
      useBlockLayout
    );

    const RenderRow = React.useCallback(
      ({ index, style }) => {
        const row = rows[index];
        prepareRow(row);
        return (
          <div {...row.getRowProps()} className="tr" style={style}>
            <div style={{ display: "flex", width: "100%" }}>
              {row.cells.map((cell) => {
                return (
                  <div
                    {...cell.getCellProps()}
                    className="td"
                    style={{
                      width: "25%",
                    }}
                  >
                    {cell.render("Cell")}
                  </div>
                );
              })}
            </div>
          </div>
        );
      },
      [prepareRow, rows]
    );

    return (
      <div {...getTableProps()} className="table">
        <div {...getTableBodyProps()}>
          <FixedSizeList
            height={height}
            itemCount={rows.length}
            itemSize={30}
            width="100%"
            // style={{
            //   overflowY: "hidden",
            // }}
            height={height}
          >
            {RenderRow}
          </FixedSizeList>
        </div>
      </div>
    );
  }

  const columns = React.useMemo(
    () => [
      {
        accessor: "test_id",
      },
      {
        accessor: "ARG_prediction",
      },

      {
        accessor: "resistance_category",
      },
      {
        accessor: "probability",
      },
    ],
    []
  );
  return (
    <Styles>
      <div className="table-container">
        <div className="table-header">
          <HeaderMenu data={d.data} label="ID" column="test_id" />
          <HeaderMenu
            data={d.data}
            label="Arg/non-Arg"
            column="ARG_prediction"
          />
          <HeaderMenu
            data={d.data}
            label="Resistance category"
            column="resistance_category"
          />
          <HeaderMenu data={d.data} label="Probability" column="probability" />
          {d.data.length > 17 ? (
            <div
              style={{
                width: scrollbarWidth(),
              }}
            ></div>
          ) : null}
        </div>
        {filterData == null ? (
          <Table columns={columns} data={dataRender.slice(0, 2000)} />
        ) : (
          <Table columns={columns} data={filterData.slice(0, 2000)} />
        )}
      </div>
    </Styles>
  );
}

export default ResultTable;
