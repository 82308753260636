function SortAsc(data, column) {
    data.sort((a, b) => {
        if (a[column] > b[column]) {
            return 1;
        } else if (a[column] < b[column]) {
            return -1;
        }
    });
    return data;
}

function SortDasc(data, column) {
    data.sort((a, b) => {
        if (a[column] > b[column]) {
            return -1;
        } else if (a[column] < b[column]) {
            return 1;
        }
    });
    return data;
}

function Filter(data, value, column, originalData) {
    data = originalData;
    data = data.filter((a) =>
        a[column] ? a[column].toLowerCase().includes(value) : null
    );
    // console.log(data);
    return data;
}

function DeleteSort(data) {
    data.sort((a, b) => {
        if (a["test_id"] > b["test_id"]) {
            return 1;
        } else if (a["test_id"] < b["test_id"]) {
            return -1;
        }
    });
    return data;
}

export { SortAsc, SortDasc, Filter, DeleteSort };
export default function HandleJson() {
    return null;
}