import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ProfileIcon from "../../img/profileIcon.svg";
import PTRootUIMediumWoff2 from "../../font/PT/PT-Root-UI_Medium.woff2";
import { Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import GoogleLogout from "../signin/GoogleLogout";
import { connect } from "react-redux";
import { signOut } from "../../actions";

const PTRootUIMedium = {
  fontFamily: "PTRootUIMedium",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
        local('PTRootUIMedium'),
        local('PT-Root-UI_Medium'),
        url(${PTRootUIMediumWoff2}) format('woff2')
      `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const theme = createTheme({
  typography: {
    fontFamily: "PTRootUIMedium",
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [PTRootUIMedium],
      },
    },
    MuiButton: {
      root: {
        padding: "0",
        minWidth: "0",
      },
    },
  },
});

const StyledMenu = withStyles({
  paper: {
    borderRadius: "2px",
    boxShadow: "0px 2px 12px lightGrey",
    border: "none",
    overflowX: "unset",
    overflowY: "unset",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0.5,
      left: 0,
      width: 10,
      height: 10,
      backgroundColor: "white",
      transform: "translate(80px, -50%) rotate(-45deg)",
      border: "none",
      clipPath:
        "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))",
    },
  },
})((props) => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const ProfileAvatar = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div
        style={{
          height: "96px",
          display: "flex",
          marginLeft: "0",
          float: "left",
        }}
      >
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          onClick={handleClick}
          style={{
            border: "none",
            boxShadow: "none",
            backgroundColor: "transparent",
          }}
        >
          <img
            alt="profileIcon"
            src={ProfileIcon}
            style={{
              width: "36px",
              height: "36px",
            }}
          />
        </Button>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{
            padding: "0",
            marginLeft: "4px",
            marginTop: "-10px",
          }}
        >
          <Link to={`/profile`}>
            <StyledMenuItem
              style={{
                padding: "10px 24px 10px 24px",
              }}
            >
              <ListItemText
                primary="My Profile"
                style={{
                  color: "#3D5A80",
                }}
              />
            </StyledMenuItem>
          </Link>
          <Link to={`/taskCenter`}>
            <StyledMenuItem
              style={{
                padding: "10px 24px 10px 24px",
              }}
            >
              <ListItemText
                primary="Task Center"
                style={{
                  color: "#3D5A80",
                }}
              />
            </StyledMenuItem>
          </Link>

          <Divider
            style={{
              width: "80%",
              margin: "6px auto",
            }}
          />
          <StyledMenuItem
            style={{
              color: "#F87700",
              padding: "4px 100px 10px 24px",
            }}
          >
            {/*<ListItemText primary="Logout" />*/}
            <GoogleLogout userId={props.userId} token={props.token} />
          </StyledMenuItem>
        </StyledMenu>
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userId: state.auth.Id,
    token: state.auth.token,
  };
};

export default connect(mapStateToProps, { signOut })(ProfileAvatar);
