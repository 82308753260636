import React from "react";
import HeaderWhite from "./headerWhite/HeaderWhite";
import { Grid, Divider } from "@material-ui/core";
import Footer from "./Footer";
import FaqContent from "./FaqContent";
import { Link } from "react-router-dom";
import useTabTitle from "../utils/useTabTitle";

const FAQ = () => {
  useTabTitle("ARGNet | FAQ")
  return (
    <div>
      <div className="external-pages-container">
        <HeaderWhite />
        <Grid item container>
          <Grid item sm xs></Grid>
          <Grid item sm={8} xs={12}>
            <div className="external-pages">
              <h2>FAQ</h2>
              <h1>
                Frequently Asked Questions Top <span>X</span>.
              </h1>
              <div className="text-button-container">
                <div className="text-container-l">
                  <p>
                    You have completed all the steps of registration, you are
                    welcome! You have completed all the steps of registration,
                    you are welcome!You have completed all the steps of
                    registration, you are welcome! You have completed all the
                    steps of registration, you are welcome!
                  </p>
                </div>
                <Link to={`/contactUs`}>
                  <button className="form-input-btn">Contact Us</button>
                </Link>
              </div>
              <Divider
                style={{
                  marginTop: "36px",
                }}
              />
              <div
                className="faq-content-container"
                style={{
                  marginTop: "48px",
                  marginBottom: "48px",
                  borderBottom: "rgba(0, 0, 0, 0.12) 1px solid",
                }}
              >
                <FaqContent />
              </div>
              <Link to={`/contactUs`}>Other question? Contact us ></Link>
            </div>
          </Grid>
          <Grid item sm xs></Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default FAQ;
