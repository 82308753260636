import React from "react";
import ArgPieChart from "./ArgPieChart";
import PredictionPieChart from "./PredictionPieChart";
import * as d3 from "d3";
import {
  dataMapToBinaryArg,
  dataMapToMutipleCat,
} from "./convertToD3Format.js";

export default function PieChartTable({binary, categories}) {
  // let argColor = ["#3D5A80", "#98C1D9"];

  let isClicked = false;
  let dataArg = binary
  let dataResistance = categories


  

  const predictionColor = d3.quantize((t) => d3.interpolateRdBu(t), dataResistance.length).reverse()
  const argColor = d3.quantize((t) => d3.interpolateRdBu(t), 2).reverse()

  function unClick() {
    d3.selectAll(".createArcArg")
      .style("fill-opacity", 1)
      .transition()
      .duration(600);
    d3.selectAll(".legendArg")
      .style("fill-opacity", 1)
      .transition()
      .duration(600);
    d3.selectAll(".createArc")
      .style("fill-opacity", 1)
      .transition()
      .duration(600);
    d3.selectAll(".legend").style("fill-opacity", 1).transition().duration(600);
  }
  d3.select("body").on("click", function () {
    isClicked = false;
    unClick();
  });
  return (
    <div className="pie-chart-container">
      <div className="pie-chart-cell-container">
        <div className="pie-chart-header">
          <p>ARG/non-ARG</p>
        </div>
        <div className="pie-chart-cell">
          <ArgPieChart
            data={dataArg}
            width={160}
            height={160}
            innerRadius={50}
            outerRadius={80}
            colorScale={argColor}
          />
        </div>
      </div>
      <div className="pie-chart-cell-container">
        <div className="pie-chart-header">
          <p>Resistance category</p>
        </div>
        <div className="pie-chart-cell">
          <PredictionPieChart
            data={dataResistance}
            width={160}
            height={160}
            innerRadius={50}
            outerRadius={80}
            colorScale={predictionColor}
            className="pie-chart-cell"
          />
        </div>
      </div>
    </div>
  );
}
