import React from "react";
import Grid from "@material-ui/core/Grid";
import logo from "../logo.svg";
import facebook from "../img/facebook.svg";
import twitter from "../img/twitter.svg";
import github from "../img/github.svg";
import instagram from "../img/instagram.svg";
import linkedin from "../img/linkedin.svg";
import "../App.css";
import { Link } from "react-router-dom";
import hkumedlogo from "../img/hkumedlogo.png";
import d24hlogo from "../img/D24H.png";

export default function Footer() {
  return (
    <div
      style={{
        backgroundColor: "#3D5A80",
        width: "100%",
        height: "auto",
        paddingTop: "40px",
        paddingBottom: "20px",
      }}
      className="footer"
    >
      <div className="footer-container">
        <Grid container>
          <Grid item sm={2} xs="auto"></Grid>
          <Grid item md={3} sm={8} xs={12}>
            <div
              className="drawer-logo-container"
              style={{
                display: "flex",
              }}
            >
              <img
                src={logo}
                alt="logo"
                style={{
                  height: "48px",
                  marginBottom: "20px",
                }}
              />
              <p
                style={{
                  fontSize: "1.8rem",
                  fontFamily: "PTRootUIWebBold",
                  color: "white",
                  marginTop: "1rem",
                  marginLeft: "16px",
                  letterSpacing: "",
                }}
              >
                <span
                  style={{
                    color: "#F87700",
                  }}
                >
                  ARG
                </span>
                Net
              </p>
            </div>
            <div
              className="introduction"
              style={{
                textAlign: "left",
                width: "100%",
              }}
            >
              <p>
                An online platform implementing a neural network to identify and
                classify antibiotic resistance genes. 🔗
              </p>
            </div>
            <div className="social-media-container">
              <a href="#">
                <img src={facebook} alt="facebook" />
              </a>
              <a href="#">
                <img src={twitter} alt="twitter" />
              </a>
              <a href="#">
                <img src={github} alt="github" />
              </a>
            </div>
          </Grid>
          <Grid item md={5} sm={8} xs={12}>
            <div className="footer-navigater-container">
              <div className="footer-navigater" id="footer-navigater1">
                <h2>Learn More</h2>
                <br />
                <Link to={`/`}>
                  <p>Home</p>
                </Link>
                <Link to={`/about`}>
                  <p>About</p>
                </Link>
                <Link to={`/tutorial`}>
                  <p>Tutorial</p>
                </Link>
                {/* <Link to={`/recentUpdate`}>
                  <p>Recent Updates</p>
                </Link> */}
              </div>

              <div className="footer-navigater" id="footer-navigater2">
                <h2>Who Are We</h2>
                <br />
                <Link to={`/ourTeam`}>
                  <p>Our Team</p>
                </Link>
                <a href="#">
                  <p>D²4H</p>
                </a>
                <a href="#">
                  <p>HKUMed</p>
                </a>
              </div>

              <div className="footer-navigater" id="footer-navigater2">
                <h2>Support</h2>
                <br />
                {/*<Link to={`/faq`}>
                  <p>FAQ</p>
              </Link>*/}
                <Link to={`/contactUs`}>
                  <p>Feedback</p>
                </Link>
                <Link to={`/tutorial`}>
                  <p>Terms of Use</p>
                </Link>
                <Link to={`/privacypolicy`}>
                  <p>Privacy Policy</p>
                </Link>
              </div>
            </div>
          </Grid>
          <Grid item sm={2} xs="auto"></Grid>
          <Grid container>
            <Grid item md={2} sm="auto"></Grid>
            <Grid
              item
              md={8}
              sm={8}
              xs={12}
              style={{
                margin: "auto",
              }}
            >
              {/* <div
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  borderBottom: "1px #5A7B7E solid",
                  marginBottom: "30px",
                }}
              ></div> */}
              <div className="SupportLogos">
                <a href="https://www.d24h.hk/" target="_blank">
                  <img
                    alt="d24hlogo"
                    src={d24hlogo}
                    style={{
                      marginRight: "60px",
                      height: "60px",
                    }}
                  />
                </a>
                <a href="https://www.med.hku.hk/" target="_blank">
                  <img
                    alt="hkumedlogo"
                    src={hkumedlogo}
                    style={{
                      height: "44px",
                      marginTop: "12px",
                    }}
                  />
                </a>
              </div>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  borderBottom: "1px #657793 solid",
                  marginBottom: "30px",
                  marginTop: "30px",
                }}
              ></div>
              <p
                style={{
                  textAlign: "center",
                }}
              >
                © 2022 ARGNet. All rights reserved.
              </p>
              {/* <p>Services | Securaty policies</p> */}
            </Grid>
            <Grid item md={2} sm="auto"></Grid>
          </Grid>
          <Grid item sm={2} xs="auto"></Grid>
        </Grid>
      </div>
    </div>
  );
}
