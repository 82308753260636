import React from "react";
import { Link } from "react-router-dom";
import embarrassed from "../img/embarrassed.svg";

const TokenExpired = () => {
  return (
    <div
      tw-container
      style={{
        backgroundColor: "#98ACAF",
        height: "100vh",
        position: "absolute",
        width: "100%",
      }}
    >
      <div
        className="tw-paper"
        style={{
          width: "686px",
          height: "686px",
          margin: "auto",
          position: "relative",
          top: "50%",
          marginTop: "-343px",
          borderRadius: "2px",
          backgroundColor: "#FFF",
          boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
        }}
      >
        <img
          alt="emarrassed"
          src={embarrassed}
          style={{
            marginTop: "60px",
          }}
        />
        <h1
          style={{
            color: "black",
            fontSize: "2rem",
            letterSpacing: "1px",
            marginTop: "50px",
            marginBottom: "20px",
          }}
        >
          Sorry! The page information is expired.
        </h1>
        <span>Please check the link and try again.</span>
        <div
          style={{
            marginTop: "50px",
          }}
        >
          <Link to={`/`}>&lt; Go to ArgNet</Link>
        </div>
      </div>
    </div>
  );
};

export default TokenExpired;
