import * as React from "react";
import "../../styles/core.css";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { StyledEngineProvider } from "@mui/styled-engine";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { validation } from "../../utils/validation";
import {preprocessing} from '../../utils/preprocessing'
import { connect } from "react-redux";
import { BACKENDURL } from "../../contants/backendSettings";
import { FRONTENDURL } from "../../contants/frontendSettings";
import { useForm } from "react-hook-form";
import * as DisplayedMessages from "../DisplayedMessages";
import {CircularProgress } from "@material-ui/core";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Preview = ({
  handleClose,
  title,
  value,
  model,
  fileName,
  // sequenceName,
  open,
  selectedFile,
  inputSequence,
  setSelectedFile,
  setInputSequence,
  ...props
}) => {
  const [fileError, setFileError] = React.useState({
    state: false,
    message: "No Error",
  });

  const [sequenceError, setSequenceError] = React.useState({
    state: false,
    message: "No Error",
  });

  const [requestSent, setRequestSent] = React.useState({
    state: false,
    message: undefined,

  })
  const [isSubmitting , setIsSubmitting] = React.useState(false)


  const [sequenceData , setSequenceData] = React.useState(null)
  const [fileData , setFileData] = React.useState(null)


  const {
    register,
    handleSubmit,
    setError,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const fileValidation = (data, setFileError, model, name) => {
    // console.log("The input file is ", data);
    setFileError({
      state: false,
      message: "No error",
    });
    if (!data) {
    } else {
      let reader = new FileReader();
      reader.readAsText(data);
      reader.onload = () => {
        let { result } = reader;
        let strippedResult = preprocessing(result);
        // console.log("strippedResult: ", strippedResult)
        let validated = validation(strippedResult, model);
        if (validated.state === 2) {
          let content = strippedResult
          let blob = new Blob([content], {type : 'text/plain'})
          let file = new File([blob], name , {type : "text/plain"})
          setFileData(file)
          setFileError({
            state: false,
            message: "No error",
          });
        } else {
          // console.log("enter into error block");
          setFileError({ state: true, message: validated.message });
        }
      };
    }
  };

  const sequenceValidation = (data, setSequenceError, model) => {
    console.log("data [Preview.js]:", data);
    setSequenceError({
      state: false,
      message: "No error",
    });
    if (!data) {
    } else {
      let processedData = preprocessing(data)
      let validated = validation(processedData, model);
      if (validated.state === 2) {
        setSequenceData(processedData)
        setSequenceError({
          state: false,
          message: "No error",
        });
      } else {
        // console.log("enter into error block");
        setSequenceError({ state: true, message: validated.message });
      }
    }
  };

  const onSubmit = async (data) => {
    if (!fileError.state && !sequenceError.state) {
      setIsSubmitting(true);
      if (fileData && !sequenceData) {
        let formData = new FormData();
        let uploaded = true;
        try {
          formData.append("fastaFile", fileData, fileData.name);
          formData.append("email", data.email);
          formData.append("projectName", data.projectName);
        } catch (e) {
          uploaded = false;
        }
        if (uploaded) {
          // send request to the backend
          try {
            await axios
              .post(
                `${BACKENDURL}/deeplearning/${model}${
                  props.isSignedIn ? "/" + props.userId : ""
                }`,
                formData
              )
              .then((res) => {
                // console.log(res);
                if (res.status !== 200) {
                  setRequestSent({
                    state: true,
                    message: `Failed to create, please try again later.`,
                  });
                  setIsSubmitting(false)
                } else {
                  // console.log("run into response success block");
                  setRequestSent({
                    state: true,
                    // message: `${FRONTENDURL}/result/${model}/${selectedFile.name}/${res.data.token}`

                    message : `${FRONTENDURL}/processtask/${res.data.token}`
                  })
                  setIsSubmitting(false);

                }
              });
          } catch (e) {
            // console.log("run into error block");
            setIsSubmitting(false);
          }
        }
      } else if (!fileData && sequenceData) {
        let formData = new FormData();
        formData.append("sequence", sequenceData);
        formData.append("email", data.email);
        formData.append("projectName", data.projectName);
        try {
          await axios
            .post(
              `${BACKENDURL}/deeplearning/${model}${
                props.isSignedIn ? "/" + props.userId : ""
              }`,
              formData
            )
            .then((res) => {
              // console.log(res);
              if (res.status !== 200) {
                // console.log("run into response error block");
                setRequestSent({
                  state: true,
                  message: `Failed to create, please try again later.`,
                });
                setIsSubmitting(false)
              } else {
                // console.log("run into response success block");
                setRequestSent({
                  state: true,
                  message: `${FRONTENDURL}/processtask/${res.data.token}`,
                });
                setIsSubmitting(false)
              }
            });
        } catch (e) {
          // console.log("run into error block");
          setIsSubmitting(false)
        }
      }

      reset({
        ...getValues(),
        email: props.isSignedIn ? getValues().email : "",
        projectName: "",
      });

      setSelectedFile();
      setInputSequence();
    }
  };

  React.useEffect(() => {
    setRequestSent({
      state: false,
      message: undefined,
    });
  }, [open]);

  // listen to the changes of fileInput
  React.useEffect(() => {
    if (selectedFile) {
      fileValidation(selectedFile, setFileError, model, selectedFile.name);
    }
    setIsSubmitting(false)
  }, [selectedFile, model]);

  // listen to the changes of sequenceInput
  React.useEffect(() => {

    
      sequenceValidation(inputSequence, setSequenceError, model);

    setIsSubmitting(false)
    // let timer = setTimeout(() => {
    //   sequenceValidation(inputSequence, setSequenceError, model)
    // },2000)
    // return () => {
    //   clearTimeout(timer)
    // }
  }, [inputSequence, model]);

  // React.useEffect(() => {
  //   if (inputSequence) {
  //     sequenceValidation(inputSequence, setSequenceError, model)
  //   }
  //   if (selectedFile) {
  //     fileValidation(selectedFile, setFileError, model)
  //   }
  // }, [model])

  if (!requestSent.state) {
    return (
      <StyledEngineProvider injectFirst>
        <BootstrapDialog
          onClose={() => handleClose(false)}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => handleClose(false)}
          >
            Task Preview
          </BootstrapDialogTitle>
          <DialogContent
            style={{
              marginTop: "10px",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)} id="previewForm">
              <div className="preview-cell">
                <label>Project Name : </label>
                <input
                  className="preview-input"
                  type="text"
                  placeholder="Untitled Project"
                  name="projectName"
                  {...register("projectName", {
                    required: true,
                    maxLength: 100,
                  })}
                ></input>
                {errors.projectName?.type === "required" && (
                  <span style={{ color: "red" }}>
                    {DisplayedMessages.REQUIRED}
                  </span>
                )}
                {errors.projectName?.type === "maxLength" && (
                  <span style={{ color: "red" }}>
                    {DisplayedMessages.LENGTH_100}
                  </span>
                )}
              </div>
              <div className="preview-cell">
                <label>Email : </label>
                <input
                  className="preview-input"
                  type="email"
                  placeholder="Optional"
                  defaultValue={props.isSignedIn ? props.userEmail : ""}
                  name="email"
                  {...register("email", {
                    required: true,
                    maxLength: 255,
                    pattern:
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  })}
                ></input>
                {errors.email?.type === "required" && (
                  <span style={{ color: "red" }}>
                    {DisplayedMessages.REQUIRED}
                  </span>
                )}
                {errors.email?.type === "pattern" && (
                  <span style={{ color: "red" }}>
                    {DisplayedMessages.EMAIL_FORMAT}
                  </span>
                )}
              </div>
              <div className="preview-cell">
                <label>Model : </label>
                <p>{title[value]}</p>
              </div>
              <div className="preview-cell">
                <label>Input File : </label>
                <p>{fileName}</p>
                {fileError.state && (
                  <p style={{ color: "red" }}>{fileError.message}</p>
                )}
              </div>
              <div className="preview-cell">
                <label>Input Sequence : </label>
                <p>{inputSequence ? inputSequence : "Not applicable"}</p>
                {sequenceError.state && (
                  <p style={{ color: "red" }}>{sequenceError.message}</p>
                )}
              </div>
              <div className="preview-buttons">
                {/* <button
                  className="secondary-button"
                  style={{
                    minWidth: "120px",
                  }}
                >
                  Back
                </button> */}
                <button
                type="submit"
                className={
                  fileError.state || sequenceError.state || isSubmitting
                    ? "disabled-button"
                    : "primary-button"
                }
                style={{
                  minWidth: "120px",
                  marginLeft: "30px",
                }}
                form="previewForm"
                disabled={fileError.state || sequenceError.state || isSubmitting}
              >
                {isSubmitting ? (
                  <CircularProgress
                    size={24}
                    className="spinner"
                    color="#c4c4c4"
                    thickness={5}
                  />
                ) : (
                  "Submit"
                )}
              </button>
              </div>
            </form>
          </DialogContent>
        </BootstrapDialog>
      </StyledEngineProvider>
    );
  } else {
    return (
      <StyledEngineProvider injectFirst>
        <BootstrapDialog
          onClose={() => handleClose(true)}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"

            onClose={() => handleClose(true)}
          >
            Task Preview
          </BootstrapDialogTitle>
          <DialogContent
            style={{
              marginTop: "10px",
            }}
          >
            <a href={requestSent.message}>Please check the status here.</a>
          </DialogContent>
        </BootstrapDialog>
      </StyledEngineProvider>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userEmail: state.auth.email,
    token: state.auth.token,
    userId: state.auth.Id,
    refreshToken: state.auth.refresh,
  };
};

export default connect(mapStateToProps, {})(Preview)
