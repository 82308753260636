import React, {useState} from "react";
// import { GoogleLogin } from "react-google-login";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { connect } from "react-redux";
import "./FormSignin.css";
import refreshTokenSetup from "../../utils/refreshToken";
import GooglePng from "./Google.png";
import { signIn, signOut } from "../../actions";
import mapEmailToDetails from "../../utils/mapEmailToDetails";
import * as DisplayedMessages from "../DisplayedMessages";
import { BACKENDURL } from "../../contants/backendSettings";
import { OAUTH_CLIENT_KEY } from "../../contants/keys"
import { CircularProgress } from "@material-ui/core";

const clientId = OAUTH_CLIENT_KEY


// https://reactjsexample.com/google-oauth2-using-the-new-google-identity-services-sdk-for-react/
// https://github.com/MomenSherif/react-oauth/issues/5
// https://github.com/MomenSherif/react-oauth/issues/6


const Login = (props) => {



  const [isLoading, setIsLoading] = useState(false)
  const connectToGoogleAuth = async (data) => {
    return new Promise((resolve, reject) => {
      try {
        const response = fetch(`${BACKENDURL}/account/auth/google-oauth2`, {
          method : 'POST',
          headers : {
            "Content-Type": "application/json"},
             body : JSON.stringify(data)
        }).then(res => res.json())
        resolve(response)
      } catch (error) {
      if (error.message === "Failed to fetch") {
        reject(DisplayedMessages.CONNECTION_ERROR)
      } else {
        reject(error.message);
      }
      }
    })
  };

  const connectToGetEmail = async (accessToken) => {
    return new Promise((resolve, reject) => {
      try {
        const response = fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
          method : "GET",
          // mode : 'cors',
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
          },

        }).then(res => res.json())
        resolve(response)
      } catch (error) {
        reject(error.message);
      }
    })
  }

  const onFailure = console.log

  const onSuccess = async (res) => {
    try {
      setIsLoading(true);
      console.log("[Login Success] currentUser: ", res);
      const {access_token, ...otherGoogleInfo} = res;

      /////// this is the implicit flow
      console.log(access_token)
      const {email, sub, ...otherAPIInfo} = await connectToGetEmail(access_token)
      console.log({email, sub, ...otherAPIInfo})
      const {token , refresh} = await connectToGoogleAuth({access_token})
      const {first_name , last_name, userId, locationCode, ...otherUserInfo} = await mapEmailToDetails(email)
      console.log({token, refresh})
      setIsLoading(false);
      props.signIn(
        "Google",
        sub,
        userId,
        first_name + "_" + last_name,
        token,
        email,
        refresh,
        locationCode
      );
    } catch (err) {
      setIsLoading(false);
      console.log(err.message)
    }
    //   const data = { access_token: res.tokenObj.access_token };
    //   // connectToGoogleAuth will be undefined if database is reset.
    //   const { token, refresh } = await connectToGoogleAuth(data);
    //   if (!(token && refresh)) {
    //     throw Error("Token is undefined.");
    //   } else {
    //     // use mapEmailsToDetails to get userId
    //     const details = await mapEmailToDetails(email);
    //     console.log(details);
    //     // pass the info to signIn
    //     props.signIn(
    //       "Google",
    //       platformId,
    //       details.userId,
    //       details.first_name + "_" + details.last_name,
    //       token,
    //       email,
    //       refresh,
    //       details.locationCode
    //     );
    //     refreshTokenSetup(res);
    //   }
    // } catch (err) {
    //   console.log(err.message);
    // }
  };

  const login = useGoogleLogin({
    onSuccess, 
    onFailure,
    flow : 'implicit',
    // flow : 'auth-code',
    scope : "profile email openid"
  })


  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {/*<GoogleLogin
        clientId={clientId}
        buttonText="Login with Google"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        isSignedIn={true}
        reponseType="id_token"
        // uxMode="redirect"
        // redirectUri="http://localhost:3000"
        render={(renderProps) => (
          <button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            style={{
              border: "none",
              background: "none",
              padding: "0",
              width: "100%",
              display: "block",
              textAlign: "left",
              alignItems: "left",
            }}
          >
            <div
              className="google-btn"
              style={{
                display: "flex",
                height: "42px",
                border: "lightgrey solid 1px",
                borderRadius: "2px",
                marginTop: "40px",
                backgroundColor: "white",
              }}
            >
              <img
                className="googleIcon"
                alt="googlePng"
                src={GooglePng}
                style={{
                  padding: "6px",
                  width: "auto",
                  height: "30px",
                }}
              />
              <span
                style={{
                  margin: "auto",
                }}
              >
                Login with Google&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </div>
          </button>
        )}
              />*/}
      <button
        onClick={() => login()}
        disabled={isLoading}
        style={{
          border: "none",
          background: "none",
          padding: "0",
          width: "100%",
          display: "block",
          textAlign: "left",
          alignItems: "left",
        }}
      >
        <div
          className="google-btn"
          style={{
            display: "flex",
            height: "42px",
            border: "lightgrey solid 1px",
            borderRadius: "2px",
            marginTop: "40px",
            backgroundColor: "white",
          }}
        >
          <img
            className="googleIcon"
            alt="googlePng"
            src={GooglePng}
            style={{
              padding: "6px",
              width: "auto",
              height: "30px",
            }}
          />
          {!isLoading ? 
                  (<span
                  style={{
                    margin: "auto",
                  }}
                >
                  Login with Google&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>) :  
                  <CircularProgress
                  size={24}
                  style={{
                    margin: "auto",
                  }}
                  color="#c4c4c4"
                  thickness={5}
                />
        }
        </div>
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { isSignedIn: state.auth.isSignedIn };
};

export default connect(mapStateToProps, {
  signIn,
  signOut,
})(Login);
