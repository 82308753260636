import "../../App.css";
import React, { Component } from "react";
import img from "../../img/img.png";
import lsaa from "../../img/LSAA.png";
import ssaa from "../../img/SSAA.png";
import lsnt from "../../img/LSNT.png";
import ssnt from "../../img/SSNT.png";
class LandingModels extends Component {
  render() {
    return (
      <div className="entries-container">
        <div className="entries-title">
          <h3>
            You can perform ARG prediction based on four types of our deep
            learning models.
          </h3>
          {/*<p>
            You have completed all the steps of registration, you are
            welcome!You have completed all the steps of registration, you are
            welcome!You have completed all the steps of registration, you are
            welcome!You have completed all the steps
          </p>*/}
        </div>
        <div className="entries-content">
          <div className="entry">
            <img alt="entry1" src={lsaa} />
            <p>Amino Acid Long Sequence Model</p>
            <p style={{ color: "#f87700" }}>(over 50aa)</p>
          </div>
          <div className="entry">
            <img alt="entry1" src={ssaa} />
            <p>Amino Acid Short Sequence Model</p>
            <p style={{ color: "#f87700" }}>(30-50aa)</p>
          </div>
          <div className="entry">
            <img alt="entry1" src={lsnt} />
            <p>Nucleotide Long Sequence Model</p>
            <p style={{ color: "#f87700" }}>(over 150nt)</p>
          </div>
          <div className="entry">
            <img alt="entry1" src={ssnt} />
            <p>Nucleotide Short Sequence Model</p>
            <p style={{ color: "#f87700" }}>(100-150nt)</p>
          </div>
        </div>
      </div>
    );
  }
}
export default LandingModels;
