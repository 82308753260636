function secondsToHms(d) {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);
    let hDisplay = h > 0 ? (h > 9 ? "" : "0") + h + ":" : "00:";
    let mDisplay = m > 0 ? (m > 9 ? "" : "0") + m + ":" : "00:";
    let sDisplay = s > 0 ? (s > 9 ? "" : "0") + s : "00";
    return hDisplay + mDisplay + sDisplay;
  }

export const jobTimeConvertToStr = (jobTime) => {
    const UTCdate = new Date(jobTime)
    let date = UTCdate.toLocaleDateString("en-GB").split("/").reverse().join("-");
    let time = UTCdate.toLocaleTimeString("en-GB");
    return time + ", " + date;
}

export const jobTimeDeltaConvertToStr = (jobTimeDelta, dateState, jobTime) => {
    if (jobTimeDelta) {
        return secondsToHms(jobTimeDelta)
    } else {
        const UTCdate = new Date(jobTime)
        return secondsToHms((dateState - UTCdate) / 1000)
    }
}