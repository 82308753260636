import * as DisplayedMessages from "../components/DisplayedMessages";
import {BACKENDURL} from "../contants/backendSettings"
import {ChangeInfo} from '../actions'

const generateAccessToken = async (isSignedIn, refreshKey, callback) => {
  // console.log(isSignedIn, refreshKey);
  if (isSignedIn) {
    try {
      await fetch(`${BACKENDURL}/account/api/token/refresh`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ refresh: refreshKey }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.refresh) {
            throw new Error(DisplayedMessages.REFRESH_REQUIRED);
          }
          if (res.detail) {
            if (res.detail === "Token is invalid or expired") {
              throw new Error(DisplayedMessages.REFRESH_TOKEN_EXPIRED);
            } else {
              throw new Error(DisplayedMessages.UNCLEAR_ERROR);
            }
          }
          if (res.access) {
            callback(res.access);
            ChangeInfo(res.access);
          }
        });
    } catch (err) {
      // console.log(err.message);
    }
  }
};

export default generateAccessToken;
