import React from "react";
import Grid from "@material-ui/core/Grid";
import ExternalHeader from "../ExternalHeader";
import processing from "../../icons/processing.png";
import finished from "../../icons/finished.png";
import { useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  jobTimeConvertToStr,
  jobTimeDeltaConvertToStr,
} from "../../utils/time";
import { BACKENDURL } from "../../contants/backendSettings";
import axios from "axios";
import history from "../history";
import useTabTitle from '../../utils/useTabTitle'

export default function ProcessTask() {
  useTabTitle("ARGNet | Task")
  const { token } = useParams();

  const [dateState, setDateState] = React.useState(new Date());
  const [data, setData] = React.useState({
    model: null,
    file: null,
    projectName: null,
    status: null,
    submitTime: null,
    costTime: null,
  });
  const [errorMessage, setErrorMessage] = React.useState("");

  const [isResultButtonDisabled, setIsResultButtonDisabled] = React.useState(true);

  React.useEffect(() => {
    const interval = setInterval(() => setDateState(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${BACKENDURL}/deeplearning/info`, { token })
        if ("message" in response.data) {
          const message = response.data.message;

          setData({
            model: message.jobType,
            file: message.jobInputFile,
            projectName: message.jobProjectName,
            status: message.jobStatus,
            submitTime: message.jobTime,
            costTime: message.jobTimeDelta,
          });

          setIsResultButtonDisabled(message.jobStatus !== "Finished");
        } else {
          throw new Error("message is not appears in response.data")
        }
      } catch (e) {
        console.error(e)
        setErrorMessage("Server Error");
      }
    };
    fetchData();
    const interval = setInterval(() => {
        fetchData();
    }, 5000);
    return () => {
      clearInterval(interval)
    }
  }, []);

  let buttonStatus;
  let iconStatus;
  let interruptDisplay;

  if (data.status == "Finished") {
    buttonStatus = "primary-button";
    iconStatus = finished;
    interruptDisplay = "none";
  } else {
    buttonStatus = "disabled-button";
    iconStatus = processing;
    interruptDisplay = "auto";
  }

  const [open, setOpen] = React.useState(false);
  const handleInterruptOpen = () => {
    setOpen(true);
  };
  const handleInterruptClose = () => {
    setOpen(false);
  };

  const onInterrupt = (e) => {
    const cancelJob = async () => {
      if (token) {
        await axios
          .post(`${BACKENDURL}/deeplearning/cancel`, {
            token: token,
          })
          .then((res) => {
            // console.log(res.data);
            if ("message" in res.data) {
              setOpen(false);
              history.push("/prediction");
            }
            if ("error" in res.data) {
              setOpen(true);
            }
          });
      }
    };
    cancelJob();

  };

  const handleOnClick = (e) => {
    e.preventDefault();
    if (token) {
      history.push(`/result/${token}`);
    }
  };

  if (!errorMessage) {
    return (
      <div>
        <ExternalHeader />
        <Grid container>
          <Grid item sm={2} xs></Grid>
          <Grid item sm={8} xs={12}>
            <div className="task-content">
              <div className="time-container">
                <p>
                  Submitted at{" "}
                  <span>
                    {data.submitTime
                      ? jobTimeConvertToStr(data.submitTime)
                      : null}
                  </span>
                </p>
                <p>
                  <span id="costTime">
                    {data.submitTime
                      ? jobTimeDeltaConvertToStr(
                          data.costTime,
                          dateState,
                          data.submitTime
                        )
                      : null}
                  </span>{" "}
                  cost
                </p>
              </div>
              <div className="model-status-container">
                <h3>{data.projectName}</h3>
                <div className="status-container">
                  <img src={iconStatus} alt="" />
                  <p>{data.status}</p>
                </div>
              </div>
              <div className="id-file-coantiner">
                <p>
                  Model:&nbsp;
                  <span>
                    {data.model
                      ? data.model
                          .replace("ssaa", "Amino Acid Short Sequence Model")
                          .replace("lsaa", "Amino Acid Long Sequnece Model")
                          .replace("ssnt", "Nucleotide Short Sequence Model")
                          .replace("lsnt", "Nucleotide Long Sequence Model")
                      : "xxxxx"}
                  </span>
                </p>
                {data.file ? (
                  data.file !== "NA" ? (
                    <p>
                      Input File:&nbsp;<span>{data.file}</span>
                    </p>
                  ) : null
                ) : null}
              </div>
              <div className="result-button-container">
                <button
                  className="dangerous-button"
                  style={{
                    marginTop: "0",
                    marginRight: "20px",
                    display: interruptDisplay,
                  }}
                  onClick={handleInterruptOpen}
                >
                  Cancel
                </button>
                <button
                  className={buttonStatus}
                  style={{
                    margin: "0",
                  }}
                  onClick={handleOnClick}
                  disabled={isResultButtonDisabled}
                >
                  Result
                </button>
              </div>
            </div>
            <p className="task-bottom-noti">
              Please do <span>not</span> close or jump from this page during
              processing.
            </p>
          </Grid>
          <Grid item sm={2} xs></Grid>
        </Grid>
        <Dialog
          open={open}
          onClose={handleInterruptClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure to interrupt the task?"}
          </DialogTitle>
          <DialogContent>
            <p>
              Interruption is irreversible and you will lose all records of this
              task.
            </p>
          </DialogContent>
          <DialogActions>
            <button
              className="secondary-button"
              onClick={handleInterruptClose}
              style={{
                marginRight: "10px",
              }}
            >
              Back
            </button>
            <button className="primary-button" onClick={onInterrupt} autoFocus>
              Cancel
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return (
      <div>
        <p>{errorMessage}</p>
      </div>
    );
  }
}
