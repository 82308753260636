import "../../App.css";
import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import HeaderLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";
import LandingContent from "./LandingContent";
import background from "../../img/background.jpg";
import LandingModels from "./LandingModels";
import { Switch, Link } from "react-router-dom";
import { connect } from "react-redux";
import Footer from "../Footer";
import theme from "../../Theme";
import ProfileIcon from "../../img/profileIconWhite.svg";
import LoginPrompt from "./LoginPrompt";
import "../../styles/home.css";
import HeaderRightLogined from "./HeaderRightLogined";
import useTabTitle from '../../utils/useTabTitle'

const Home = ({ isSignedIn }) => {
  useTabTitle("ARGNet")
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="home">
        <div
          item
          container
          style={{
            backgroundImage: `url(${background})`,
            backgroundColor: "#3D5A80",
            backgroundSize: "cover",
            width: "100%",
            paddingBottom: "20px",
          }}
        >
          <div
            className="home-header"
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                width: "16.7%",
              }}
            >
              <HeaderLeft />
            </div>
            <div
              style={{
                width: "66.7%",
              }}
            ></div>
            <div
              style={{
                width: "16.6%",
              }}
            >
              <Switch>
                {isSignedIn ? <HeaderRightLogined /> : <HeaderRight />}
                {/* <Route path="*" component={NoMatch} /> */}
              </Switch>
            </div>
          </div>
          <LandingContent />
        </div>
        <div className="landing-login-model">
          <Switch>
            {isSignedIn ? <div></div> : <LoginPrompt />}
            {/* <Route path="*" component={NoMatch} /> */}
          </Switch>
          <LandingModels />
        </div>
        <div
          style={{
            backgroundColor: "white",
            width: "100%",
          }}
        >
          <Footer />
        </div>
      </div>
    </ThemeProvider>
  );
};
const mapStateToProps = (state) => {
  return { isSignedIn: state.auth.isSignedIn };
};
export default connect(mapStateToProps)(Home);
