export default function ConvertToCSV(objArray) {
    var array = typeof(objArray) !== 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
  
    for (var i = 0; i < array.length; i++) {
        var line = '';
    for (var index in array[i]) {
        if (line != '') line += ','
  
        line += array[i][index];
    }
  
    str += line + '\r\n';
  }
  
  return str;
  }