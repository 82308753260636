import React, { Component } from "react";
import logo from "../../logo.svg";
import TemporaryDrawer from "../Drawer";
import "../../App.css";

class HeaderLeft extends Component {
  render() {
    return (
      <div
        className="HeaderLeft"
        style={{
          height: "96px",
          display: "flex",
          marginRight: "0",
          float: "right",
        }}
      >
        <div
          className="logo"
          style={{
            width: "48px",
            height: "48px",
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "40px",
            float: "right",
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{
              width: "48px",
              height: "48px",
            }}
          />
        </div>

        <TemporaryDrawer />
      </div>
    );
  }
}
export default HeaderLeft;
