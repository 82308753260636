import history from "./components/history";
import React from "react";
import Signup from "./components/signup/Signup";
import Signin from "./components/signin/Signin";
import SuccessfulActivation from "./components/SuccessfulActivation";
import Activation from "./components/Activation";
import { Switch, Route, Router } from "react-router-dom";
import Home from "./components/home/Home";
import "./App.css";
import ForgetPassword1 from "./components/ForgetPassword1";
import ForgetPassword2 from "./components/ForgetPassword2";
import ForgetPassword22 from "./components/ForgetPassword22";
import ForgetPassword3 from "./components/ForgetPassword3";
import Profile from "./components/Profile";
import ChangePassword1 from "./components/ChangePassword1";
import ChangePassword2 from "./components/ChangePassword2";
import ChangePassword3 from "./components/ChangePassword3";
import UnclearError from "./components/unclearError";
import ServerError from "./components/serverError";
import Tutorial from "./components/Tutorial";
import PrivacyPolicy from "./components/PrivacyPolicy";
import FAQ from "./components/FAQ";
import RecentUpdate from "./components/recentUpdate/RecentUpdate";
import About from "./components/About";
import ContactUs from "./components/contactus/ContactUs";
import OurTeam from "./components/ourTeam/OurTeam";
import { Feedback } from "@material-ui/icons";
import FeedbackSuccess from "./components/FeedbackSuccess";
import Prediction from "./components/prediction/Prediction";
import taskCenter from "./components/taskCenter/TaskCenter";
import Result from "./components/result/Result";
import ProcessTask from "./components/processTask/ProcessTask";

function App() {
  return (
    <Router history={history}>
      <div className="App">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/register" component={Signup} />
          <Route path="/login" component={Signin} />
          <Route
            path="/finishActivation/:uid/:token"
            component={SuccessfulActivation}
          />
          <Route path="/activation" component={Activation} />
          <Route path="/forgetPassword1" component={ForgetPassword1} />
          <Route path="/forgetPassword2" component={ForgetPassword2} />
          <Route path="/forgetPassword3" component={ForgetPassword3} />
          <Route
            path="/forgetPassword22/:id/:token"
            component={ForgetPassword22}
          />
          <Route path="/profile" component={Profile} />
          <Route path="/changePassword1" component={ChangePassword1} />
          <Route path="/changePassword2" component={ChangePassword2} />
          <Route path="/changePassword3" component={ChangePassword3} />
          <Route path="/unclearError" component={UnclearError} />
          <Route path="/serverError" component={ServerError} />
          {/* <Route path="/spinner" component={spinner} /> */}
          {/*<Route path="/tokenExpired" component={TokenExpired} />*/}
          {/*<Route path="/tokenWrong" component={TokenWrong} />*/}
          <Route path="/tutorial" component={Tutorial} />
          <Route path="/privacypolicy" component={PrivacyPolicy} />
          {/*<Route path="/FAQ" component={FAQ} />*/}
          {/* <Route path="/recentUpdate" component={RecentUpdate} /> */}
          <Route path="/about" component={About} />
          <Route path="/contactUs" component={ContactUs} />
          <Route path="/ourTeam" component={OurTeam} />
          <Route path="/feedbackSuccess" component={FeedbackSuccess} />
          <Route path="/prediction" component={Prediction} />
          <Route path="/taskCenter" component={taskCenter} />
          <Route path="/result/:token" component={Result} />
          {/*<Route path="/result" component={Result} /> */}
          <Route path="/processTask/:token" component={ProcessTask} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
