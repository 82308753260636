import React from "react";
import HeaderWhite from "./headerWhite/HeaderWhite";
import { Grid, Divider } from "@material-ui/core";
import Footer from "./Footer";
import feature1 from "../icons/tolerance.svg";
import feature2 from "../icons/accuracy.svg";
import feature3 from "../icons/efficiency.svg";
import useTabTitle from "../utils/useTabTitle";

const About = () => {
  useTabTitle("ARGNet | About");
  return (
    <div>
      <div className="external-pages-container">
        <HeaderWhite />
        <Grid item container>
          <Grid item md={2} sm={2} xs></Grid>
          <Grid item md={8} sm={8} xs={12}>
            <div className="external-pages">
              <div className="text-container-m">
                <h2>ABOUT</h2>
                <h1>
                  What Does <span>ARG</span>Net Do?
                </h1>
                <div className="text-button-container">
                  <p>
                    A deep nueral network for robust identification and
                    annotation of antibiotic resistance genes. The input can be
                    long amino acid sequences(full length/contigs), long
                    nucleotide sequences, short amino acid reads (30-50aa),
                    short nucleotide reads (100-150nt) in fasta format. If your
                    input is short reads you should assign 'argnet-s' model, or
                    if your input is full-length/contigs you should assign
                    'argnet-l' to make the predict.
                  </p>
                </div>
              </div>
              <div className="external-page-divider" />
              <div className="icon-feature-container">
                <div className="icon-feature">
                  <img alt="feature1" src={feature1} />
                  <p>Tolerance</p>
                </div>
                <div className="icon-feature">
                  <img alt="feature2" src={feature2} />
                  <p>Accuracy</p>
                </div>
                <div className="icon-feature">
                  <img alt="feature3" src={feature3} />
                  <p>Efficiency</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md sm={2} xs></Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};
export default About;
