import React from "react";
import HeaderWhite from "./headerWhite/HeaderWhite";
import { Grid, Divider } from "@material-ui/core";
import Footer from "./Footer";
import useTabTitle from "../utils/useTabTitle";

const PrivacyPolicy = () => {
    useTabTitle("ARGNet | Privacy Policy");
    return (
        <div>
            <div className="external-pages-container">
                <HeaderWhite />
                <Grid item container>
                    <Grid item md={2} sm={2} xs></Grid>
                    <Grid item md={8} sm={8} xs={12}>
                        <div className="external-pages">
                            <div className="text-container-m">
                                <h2>Last Updated: 16, February 2024</h2>
                                <h1>
                                    Privacy Policy for ARGNet
                                </h1>
                                <div>
                                    <p>
                                        At ARGNet, we are committed to protecting your data and privacy. This Privacy Policy explains how we collect, use, and disclose your personal information when you visit our website or use our services. By accessing and using our website, you acknowledge that you have read, understood, and consent to the practices described in this Privacy Policy.
                                    </p>
                                    <p>
                                        <h3>Data Collection</h3>
                                        We collect the following types of information from you:
                                        <ol>
                                            <li>
                                                <b>Contact Information</b>: When you register on our website, contact us, or request further information or services, we collect your name, email address, and country.
                                            </li>
                                            <li>
                                                <b>User Data</b>: We receive FASTA data from users for the purpose of making predictions based on the data. This data is processed and used solely for the intended purpose and is not stored or retained beyond what is necessary.
                                            </li>
                                        </ol>
                                    </p>
                                    <p>
                                        <h3>Use of Data</h3>
                                        We use the collected data for the following purposes:
                                        <ol type="1">
                                            <li>
                                                <b>Providing Access and Services</b>: We use your personal information to provide you with access to our website and to deliver the requested services, such as making predictions based on the FASTA data you provide.
                                            </li>
                                            <li>
                                                <b>Communication</b>: We may use your contact information to communicate with you, including sending administrative and technical communications, providing support, or notifying you about changes to this Privacy Policy.
                                            </li>
                                            <li>
                                                <b>Internal Purposes</b>: We may use the data for internal purposes such as statistical analysis, improving our website and services, troubleshooting issues, and enhancing the user experience.
                                            </li>
                                            <li>
                                                <b>Compliance and Legal Obligations</b>: We may disclose your personal information to comply with applicable laws, regulations, or legal processes, or to respond to lawful requests from public authorities.
                                            </li>
                                        </ol>
                                    </p>
                                    <p>
                                        <h3>Data Security</h3>
                                        We take appropriate measures to ensure the security of your personal information and protect it from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of data transmission over the internet or electronic storage is secure, and we cannot guarantee absolute security.
                                    </p>
                                    <p>
                                        <h3>Data Retention</h3>
                                        We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy and comply with our legal obligations. Once the data is no longer needed, we will securely delete or anonymize it.
                                    </p>
                                    <p>
                                        <h3>Third-Party Service Providers</h3>
                                        We may engage third-party service providers to assist us in delivering our services or to perform certain functions on our behalf. These providers may have access to your personal information but are obligated to protect its confidentiality and security and are prohibited from using it for any other purpose.
                                    </p>
                                    <p>
                                        <h3>Third-Party Links</h3>
                                        Our website may contain links to third-party websites. Please note that we are not responsible for the privacy practices or content of those websites. We encourage you to review the privacy policies of third-party websites before providing any personal information.
                                    </p>
                                    <p>
                                        <h3>Your Rights</h3>
                                        Under applicable privacy laws, you have certain rights regarding your personal information. These rights may include:
                                        <ol>
                                            <li>
                                                <b>Access</b>: You have the right to request access to the personal information we hold about you.
                                            </li>
                                            <li>
                                                <b>Correction</b>: You can request correction or update of your personal information if it is inaccurate or incomplete.
                                            </li>
                                            <li>
                                                <b>Withdrawal of Consent</b>: You have the right to withdraw your consent for the processing of your personal information.
                                            </li>
                                            <li>
                                                <b>Complaints</b>: If you have concerns about our data handling practices, you have the right to lodge a complaint with the appropriate data protection authority.
                                            </li>
                                        </ol>
                                        If you would like to exercise any of these rights or have any questions about our privacy practices, please contact us at <a href="mailto:argnetd24h@gmail.com">argnetd24h@gmail.com</a>.
                                    </p>
                                    <p>
                                        <h3>Changes to Privacy Policy</h3>
                                        We may update this Privacy Policy from time to time to reflect changes in our data practices. We will notify you of any material changes by posting the updated Privacy Policy on our website. We encourage you to review this Privacy Policy periodically.
                                    </p>
                                    <p>
                                        <h3>Contact Us</h3>
                                        If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at <a href="mailto:argnetd24h@gmail.com">argnetd24h@gmail.com</a>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item md sm={2} xs></Grid>
                </Grid>
            </div>
            <Footer />
        </div>
    );
};
export default PrivacyPolicy;
