import React from "react";
import uploadButton from "../../icons/uploadButton.png";
import removeButton from "../../icons/removeButton.png";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import json from "./prediction.json";
import axios from "axios";
import { StyledEngineProvider } from "@mui/styled-engine";
import { sampleFiles } from "./sampleFiles/sampleFiles";
import fileDownload from "js-file-download";

// to prevent from that we cannot open the same file again once uploaded
const randomGenerator = () => {
  return Math.random().toString(36).substr(2);
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function UploadFile({
  value,
  model,
  selectedFile,
  setSelectedFile,
}) {
  const fileRequirements = json.fileRequirements;
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const overSizeClose = () => {
    setOpen1(false);
  };
  const overSizeOpen = () => {
    setOpen1(true);
  };
  const onOverSize = () => {
    overSizeOpen();
  };
  const onInvalidFasta = () => {
    setOpen2(true);
  };
  const invalidFastaClose = () => {
    setOpen2(false);
  };
  const fastaDialogOpen = () => {
    setOpen3(true);
  };
  const fastaDialogClose = () => {
    setOpen3(false);
  };

  let fileName = "No file uploaded";
  if (!selectedFile) {
    fileName = "No file uploaded";
  } else {
    fileName = selectedFile.name;
  }
  const onFileChange = (event) => {
    // console.log("Enter into UploadFile.js (onFileChange)");
    if (event.target.files[0].size <= 100000000) {
      if (event.target.files[0].name.endsWith(".fasta")) {
        // console.log(event.target.files[0]);
        setSelectedFile(event.target.files[0]);
      } else {
        onInvalidFasta();
      }
    } else {
      onOverSize();
    }
  };

  const handleClick = (event) => {
    // console.log("Enter into UploadFile.js (handleClick)");
    if (!selectedFile) {
      hiddenFileInput.current.click();
    } else {
      setSelectedFile(null);
    }
  };
  const hiddenFileInput = React.useRef(null);
  let textButton = "Upload from local files";
  let iconButton = uploadButton;
  if (selectedFile) {
    textButton = "Remove";
    iconButton = removeButton;
  }

  const handleSampleFileOnClick = (e) => {
    // e.preventDefault();
    // if (!selectedFile) {
    //   let content = sampleFiles[`sampleFile_${value}`]
    //   let blob = new Blob([content], {type : 'text/plain'})
    //   let file = new File([blob], `sample_${model}.fasta`, {type : "text/plain"})
    //   setSelectedFile(file)
    // } else {
    //   console.log("you already selected a file.")
    // }
    let content = sampleFiles[`sampleFile_${value}`];
    let blob = new Blob([content], { type: "text/plain" });
    fileDownload(blob, `sampleFile_${model}.fasta`);
  };

  return (
    <div className="prediction-file">
      <h1>
        Upload a
        <button
          style={{
            color: "#3d5a80",
            fontSize: "1.5rem",
            fontFamily: "PTRootUIWebBold",
            letterSpacing: 0,
            textDecoration: "underline",
          }}
          onClick={fastaDialogOpen}
        >
          FASTA
        </button>
        file
      </h1>
      <div className="button-container">
        <button autofocus onClick={handleSampleFileOnClick}>
          Sample File
        </button>
        {/*&nbsp;&nbsp;|&nbsp;&nbsp;
  <button onClick={handleClickOpen}>File Requirements</button>*/}
      </div>
      <div className="upload-container">
        <button onClick={handleClick}>
          <img src={iconButton} alt="" id="uploadIcon" />
          <p>{textButton}</p>
        </button>
        <input
          type="file"
          name="file"
          onChange={onFileChange}
          style={{ display: "none" }}
          ref={hiddenFileInput}
          key={randomGenerator()}
        />
        <p>{fileName}</p>
      </div>
      <StyledEngineProvider injectFirst>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            File Requirements
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <p>{fileRequirements}</p>
          </DialogContent>
        </BootstrapDialog>
      </StyledEngineProvider>
      <StyledEngineProvider injectFirst>
        <BootstrapDialog
          onClose={overSizeClose}
          aria-labelledby="customized-dialog-title"
          open={open1}
        >
          <DialogContent>
            <p>
              Only files smaller than 100mb can be uploaded successfully, please
              re-select or split the upload.
            </p>
            <button
              className="primary-button"
              style={{
                float: "right",
              }}
              onClick={overSizeClose}
            >
              OK
            </button>
          </DialogContent>
        </BootstrapDialog>
      </StyledEngineProvider>
      <StyledEngineProvider injectFirst>
        <BootstrapDialog
          onClose={invalidFastaClose}
          aria-labelledby="customized-dialog-title"
          open={open2}
        >
          <DialogContent>
            <p>
              Only valid FASTA file can be uploaded successfully, please
              re-select or split the upload.
            </p>
            <button
              className="primary-button"
              style={{
                float: "right",
              }}
              onClick={invalidFastaClose}
            >
              OK
            </button>
          </DialogContent>
        </BootstrapDialog>
      </StyledEngineProvider>
      <StyledEngineProvider injectFirst>
        <BootstrapDialog
          onClose={fastaDialogClose}
          aria-labelledby="customized-dialog-title"
          open={open3}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={fastaDialogClose}
          >
            FASTA Format
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <p>
              See&nbsp;
              <a
                href="https://en.wikipedia.org/wiki/FASTA_format#:~:text=In%20bioinformatics%20and%20biochemistry%2C%20the,comments%20to%20precede%20the%20sequences."
                target="_blank"
              >
                Fasta format🔗
              </a>
              .
            </p>
            <br />
            <p>Whitespace characters will be removed automatically;</p>
            <p>Lines starting with ";" will be omitted.</p>
          </DialogContent>
        </BootstrapDialog>
      </StyledEngineProvider>
    </div>
  );
}
