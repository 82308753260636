import React from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { DialogContent } from "@material-ui/core";
import csvIcon from "../../icons/csvIcon.png";
import jsonIcon from "../../icons/jsonIcon.png";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import fileDownload from 'js-file-download';
import ConvertToCSV from "../../utils/convertToCSV";


const formats = [
  { name: "csv", icon: csvIcon },
  { name: "json", icon: jsonIcon },
];

export default function DownloadSelectBox({ onClose, selectedValue, open, data, model, inputFile, projectName }) {

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (event) => {

    const fileName = `${projectName}_${inputFile}_${model}`.replace(".", "_");
    let selection = event.target.outerText  || event.target.getAttribute("data-key")
    // console.log("(DownloadSelectBox.js) handleLisstItemClick", )
    // console.log("handleListItemClick" , selection);
    if (selection === 'csv') {
      if (data) {
        // console.log("data", data.message)
        const parsedData = JSON.stringify(data.message)
        const readyData = ConvertToCSV(parsedData)
        const blob = new Blob([readyData], {type : "text/csv"})
        fileDownload(blob, fileName + ".csv")
      } else {
        // console.log("no!!!")
      }
    } else if (selection === 'json') {
      if (data) {
        // console.log("data", data.message);
        const blob = new Blob([JSON.stringify(data.message)], {type : 'application/json'})
        fileDownload(blob, fileName + ".json")
      } else {
        // console.log("no!!!")
      }
    } else {
      // should not run into this block
      // console.log("wrong selection")
    }
    onClose(event);
  };

  


  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        Choose the file format you want
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent disablePadding>
        <List sx={{ pt: 0 }}>
          {formats.map((format) => (
            <ListItem
              button
              onClick={(event) => handleListItemClick(event)}
              disablePadding
              style={{
                paddingBottom: "12px",
                paddingTop: "12px",
              }}
              divider={true}
            >
              <ListItemIcon>
                <img src={format.icon} data-key={format.name}/>
              </ListItemIcon>
              <p>{format.name}</p>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}

DownloadSelectBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
