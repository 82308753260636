import React from "react";
import "../../App.css";
import { Link } from "react-router-dom";

export default function LandingContent() {
  return (
    <div className="LandingContent">
      <div className="Title">
        <h1>
          <span id="redI">ARG</span>Net
        </h1>
      </div>
      <div
        className="Subtitle"
        style={{
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <h2>
          A deep neural network for robust identification and annotation of
          &nbsp;
          <span
            style={{
              fontWeight: "800",
            }}
          >
            Antibiotic Resistance
          </span>
          &nbsp;genes
        </h2>
        <Link to={`prediction`}>
          <button className="homepage-button">Go Predict</button>
        </Link>
        <button className="version-button" disabled={true}>V1.0.1</button>
      </div>
    </div>
  );
}