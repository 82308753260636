import "../../styles/core.css";
import HeaderWhite from "../headerWhite/HeaderWhite";
import { Grid } from "@material-ui/core";
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Divider from "./Divider";
import PredictionTutorial from "./PredictionTutorial";
import UploadFile from "./UploadFile";
import EnterSequence from "./EnterSequence";
import { connect } from "react-redux";
import Preview from "./Preview";
import useWindowDimensions from "./useWindowDimensions.js";
import useTabTitle from "../../utils/useTabTitle";

const title = [
  "Amino Acid Long Sequence Model(>51aa)",
  "Amino Acid Short Sequence Model(30-50aa)",
  "Nucleotide Long Sequence Model(>150nt)",
  "Nucleotide Short Sequence Model(100-150nt)",
];

const Prediction = () => {
  useTabTitle("ARGNet | Go Predict");
  const { height, width } = useWindowDimensions();
  const [value, setValue] = React.useState("0");
  const [model, setModel] = React.useState("laa");
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [inputSequence, setInputSequence] = React.useState("");
  const error = {
    state: false,
    message: null,
  };
  const [open, setOpen] = React.useState(false);
  const StyledTabs = styled((props) => <Tabs {...props} />)({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontSize: theme.typography.pxToRem(15),

      padding: width > 599 ? "0 30px" : "0",
      color: "#3D5A80",
      minHeight: "32px",
      minWidth: "60px",
      width: width > 959 ? "auto" : "25%",
      "&.Mui-selected": {
        color: "white",
        backgroundColor: "#3D5A80",
        borderTopLeftRadius: "2px",
        borderTopRightRadius: "2px",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "#3D5A80",
      },
    })
  );
  const handleChange = (event, newValue) => {
    if (value !== newValue) {
      // setSelectedFile(null)
      setInputSequence("");
    }
    setValue(newValue);
    switch (newValue) {
      case "0":
        setModel("laa");
        break;
      case "1":
        setModel("saa");
        break;
      case "2":
        setModel("lnt");
        break;
      case "3":
        setModel("snt");
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  let fileName = "Not applicable";
  if (selectedFile) {
    fileName = selectedFile.name;
  }
  // let sequenceName = "Not applicable";
  // if (inputSequence) {
  //   sequenceName = inputSequence;
  // }
  let buttonClass = "disabled-button";
  let buttonStatus = true;

  let promptText = "block";
  if (
    selectedFile !== undefined &&
    selectedFile !== null &&
    inputSequence == null
  ) {
    buttonClass = "primary-button";
    buttonStatus = false;
    promptText = "none";
  } else if (
    selectedFile !== undefined &&
    selectedFile !== null &&
    inputSequence == ""
  ) {
    buttonClass = "primary-button";
    buttonStatus = false;
    promptText = "none";
  } else if (
    inputSequence !== "" &&
    inputSequence !== null &&
    selectedFile == null
  ) {
    buttonClass = "primary-button";
    buttonStatus = false;
    promptText = "none";
  } else if (
    inputSequence !== "" &&
    inputSequence !== null &&
    selectedFile == undefined
  ) {
    buttonClass = "primary-button";
    buttonStatus = false;
    promptText = "none";
  }

  // console.log("This is Prediction.js (selectedFile): ", selectedFile);
  // console.log("This is Prediction.js (inputSeqence): ", inputSequence);

  const handleClose = (booleanValue) => {
    // console.log("enter into handleClose");
    if (booleanValue) {
      // console.log("enter into handleClose booleanValue");
      setSelectedFile(null);
      setInputSequence("");
    }
    setOpen(false);
  };

  return (
    <div
      style={{
        minHeight: "100vh",
      }}
      className="prediction-page"
    >
      <HeaderWhite />
      <Grid container>
        <Grid sm={2} xs></Grid>
        <Grid item sm={8} xs={12}>
          <div className="prediction-container">
            <div className="prediction-title-container">
              <h1>{title[value]}</h1>
            </div>
            <div className="prediction-tab">
              <Box
                style={{
                  height: "32px",
                }}
              >
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="styled tabs example"
                  style={{
                    width: "100%",
                    minHeight: "32px",
                    borderBottom: "1px #3D5A80 solid",
                  }}
                >
                  <StyledTab
                    label={width > 825 ? "Amino Acid Long" : "AA Long"}
                    value="0"
                  />
                  <StyledTab
                    label={width > 825 ? "Amino Acid Short" : "AA Short"}
                    value="1"
                  />
                  <StyledTab
                    label={width > 825 ? "Nucleotide Long" : "NT Long"}
                    value="2"
                  />
                  <StyledTab
                    label={width > 825 ? "Nucleotide Short" : "NT Short"}
                    value="3"
                  />
                </StyledTabs>
              </Box>
            </div>
            <div className="prediction-content">
              <div className="prediction-right">
                <div className="prediction-right-content">
                  <PredictionTutorial model={value} />
                  {/* <img src={blank} alt="" /> */}
                </div>
              </div>
              <div className="prediction-left">
                <UploadFile
                  value={value}
                  model={model}
                  setSelectedFile={setSelectedFile}
                  selectedFile={selectedFile}
                />
                <Divider />
                <EnterSequence
                  value={value}
                  setInputSequence={setInputSequence}
                  inputSequence={inputSequence}
                  model={model}
                />
                {error.state ? (
                  <p style={{ color: "red" }}>{error.message}</p>
                ) : null}
                <div
                  style={{
                    textAlign: "left",
                    marginTop: "20px",
                  }}
                >
                  <button
                    className={buttonClass}
                    onClick={handleClickOpen}
                    disabled={buttonStatus}
                  >
                    Preview
                  </button>
                  <span
                    style={{
                      width: "100%",
                      color: "red",
                      fontSize: "0,875rem",
                      marginTop: "20px",
                      display: promptText,
                      transition: "all 0.3s ease-out",
                    }}
                  >
                    You must fill in one, and only one of sequence and file,
                    before you can submit the task.
                  </span>
                </div>
                <div
                  style={{
                    height: "30px",
                  }}
                ></div>
              </div>
              <Preview
                handleClose={handleClose}
                title={title}
                value={value}
                model={model}
                fileName={fileName}
                // sequenceName={sequenceName}
                open={open}
                selectedFile={selectedFile}
                inputSequence={inputSequence}
                setSelectedFile={() => setSelectedFile(null)}
                setInputSequence={() => setInputSequence("")}
              />
            </div>
          </div>
        </Grid>
        <Grid sm={2} xs></Grid>
      </Grid>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userEmail: state.auth.email,
    token: state.auth.token,
    userId: state.auth.Id,
    userName: state.auth.name,
    refreshToken: state.auth.refresh,
    userLocation: state.auth.location,
  };
};
export default connect(mapStateToProps, {})(Prediction);
