import React from "react";
import HeaderWhite from "../headerWhite/HeaderWhite";
import "../../styles/core.css";
import { Grid } from "@material-ui/core";
import TaskCard from "./TaskCard";
import dataFile from "./task.json";
import { connect } from "react-redux";
import axios from "axios";
import { BACKENDURL } from "../../contants/backendSettings";
import history from "../history";
import NoTask from "./NoTask";
import generateAccessToken from "../../utils/GenerateAccessToken";
import useTabTitle from '../../utils/useTabTitle';
import { CircularProgress } from "@material-ui/core";

const TaskCenter = (props) => {
  useTabTitle("ARGNet | Task Center")
  const [data, setData] = React.useState(null);
  const [isLoadingData, setIsLoadingData] = React.useState(false)

  const fetchData = async (token, number) => {
    setIsLoadingData(true)
    if (props.isSignedIn) {
      try {
        const response = await axios.post(`${BACKENDURL}/deeplearning/jobList/${props.userId}`, {}, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        })
        if (response.status === 200) {
          let resposeData = response.data.message;
          if (Array.isArray(resposeData) && resposeData.length >= 1) {
            setData(resposeData.reverse());
          }
          setIsLoadingData(false)
        } else {
          console.error(response)
          throw new Error("Response is not 200");
        }
      } catch (error) {
        if (error?.response?.status == 401) {
          setTimeout(() => {
            let temp = number + 1;
            if (temp > 3) {
              setData(null);
            } else {
              generateAccessToken(
                props.isSignedIn,
                props.refreshToken,
                (genToken) => {
                  fetchData(genToken, temp);
                }
              );
            }
          }, 3000 * number);
        } else {
          console.error(error)
          setIsLoadingData(false)
        }
      }
    } else {
      setIsLoadingData(false)
    }
  };

  React.useEffect(() => {
    fetchData(props.token, 1);

    const interval = setInterval(() => {
      fetchData(props.token, 1);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const onTaskDelete = () => {
    fetchData(props.token, 1)
  }

  if (props.isSignedIn) {
    return (
      <div>
        <HeaderWhite />
        <Grid container>
          <Grid sm={2} xs></Grid>
          <Grid item sm={8} xs={12}>
            <div className="task-center-container">
              <div
                className="prediction-title-container"
                style={{
                  borderBottom: "1px lightGrey solid",
                }}
              >
                <h1>Task Center</h1>
              </div>
              {data ? (
                data.map((a) => <TaskCard data={a} jobToken={a.jobToken} onTaskDelete={onTaskDelete} />)
              ) : (isLoadingData ? <CircularProgress
                size={24}
                className="spinner"
                color="#c4c4c4"
                thickness={5}
              /> : <NoTask />
              )}
              {/* {dataFile.message.map((a) => (
              <TaskCard data={a} />
            ))} */}
            </div>
          </Grid>
          <Grid sm={2} xs></Grid>
        </Grid>
      </div>
    );
  } else {
    history.push("/");
    return null;
  }
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    token: state.auth.token,
    userId: state.auth.Id,
    refreshToken: state.auth.refresh,
  };
};

export default connect(mapStateToProps, {})(TaskCenter);
