import React from "react";
import HeaderWhite from "./headerWhite/HeaderWhite";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { signIn, signOut } from "../actions";
import "./signin/FormSignin.css";
import history from "./history";
import { useForm } from "react-hook-form";
import * as DisplayedMessages from "./DisplayedMessages";
import generateAccessToken from "../utils/GenerateAccessToken";
import { BACKENDURL } from "../contants/backendSettings";
import useTabTitle from "../utils/useTabTitle";

const ChangePassword1 = (props) => {
  useTabTitle("ARGNet | Change Password")
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();
  // const [userId, setUserId] = useState(-1);

  // useEffect(() => {

  //   const getFetchedUser = async () => {
  //     const userEmail = props.userEmail ? props.userEmail : null;
  //     const response = await mapEmailToDetails(userEmail)
  //     if (response !== undefined && response !== null) {
  //       setUserId(response.userId)
  //     }
  //   }
  //   getFetchedUser();
  // }, []);

  const onSubmit = (data, e) => {
    e.preventDefault();
    e.target.reset();
    // console.log(data);
    let accessAcquiredTime = 0;
    const fetchVerifiedPassword = async (token) => {
      try {
        await fetch(`${BACKENDURL}/account/${props.userId}/verifyPassword`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "POST",
          body: JSON.stringify({
            old_password: data.old_password,
          }),
        })
          .then((res) => res.json())
          .then((response) => {
            // console.log(response);
            if (response === 1) {
              history.push({
                pathname: "./changePassword2",
                state: props.token,
              });
            } else if (
              response.detail === "Given token not valid for any token type"
            ) {
              // console.log("run into refresh block in changePassword1");
              generateAccessToken(
                props.isSignedIn,
                props.refreshToken,
                (res) => {
                  fetchVerifiedPassword(res);
                }
              );
              accessAcquiredTime += 1
              if (accessAcquiredTime >= 3) {
                throw new Error(DisplayedMessages.EXCEED_MAX_ACCESS_TOKEN_LIMIT)
              }
            } else {
              setError("old_password", {
                type: "match",
                message: DisplayedMessages.OLD_PASSWORD_INCORRECT,
              });
              throw new Error(DisplayedMessages.OLD_PASSWORD_INCORRECT);
            }
          });
      } catch (err) {
        if (err.message === "Failed to fetch") {
          // console.log(DisplayedMessages.CONNECTION_ERROR);
        } else {
          // console.log(err.message);
        }
      }
    };
    fetchVerifiedPassword(props.token);
  };

  if (props.isSignedIn) {
    return (
      <div className="change-password-container">
        <HeaderWhite />
        <Grid item container>
          <Grid item sm={2} xs="auto"></Grid>
          <Grid item sm={8} xs={12}>
            <div className="fgpw-form-container">
              <h1
                style={{
                  fontSize: "2rem",
                  color: "black",
                  letterSpacing: "1px",
                  marginBottom: "40px",
                }}
              >
                Verify Old Password
              </h1>
              <div className="form-inputs">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <label className="form-label"> Old Password </label>
                  <input
                    className="form-input"
                    type="password"
                    placeholder="Enter your old password"
                    {...register("old_password", { required: true })}
                  />
                  {errors.old_password?.type === "required" && (
                    <span style={{ color: "#F87700" }}>
                      {DisplayedMessages.REQUIRED}
                    </span>
                  )}
                  {errors.old_password && (
                    <span style={{ color: "#F87700" }}>
                      {errors.old_password.message}
                    </span>
                  )}
                  <br />
                  <button className="form-input-btn" type="submit">
                    Confirm
                  </button>
                </form>
              </div>
            </div>
          </Grid>
          <Grid item sm={2} xs="auto"></Grid>
        </Grid>
      </div>
    );
  } else {
    history.push("/");
    return null;
  }
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userEmail: state.auth.email,
    userId: state.auth.Id,
    token: state.auth.token,
    refreshToken: state.auth.refresh,
  };
};

export default connect(mapStateToProps, { signIn, signOut })(ChangePassword1);
