import React from "react";
import ResultHeader from "./ResultHeader";
import "../../styles/core.css";
import { Grid } from "@material-ui/core";
import ResultTable from "./ResultTable";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BACKENDURL } from "../../contants/backendSettings";
import DownloadSelectBox from "./DownloadSelectBox";
import {
  jobTimeConvertToStr,
  jobTimeDeltaConvertToStr,
} from "../../utils/time";
import PieChartTable from "./resultPieChart/PieChartTable";
// import data from "./result.json";
import HandleJson from "./HandleJson";
import fileDownload from "js-file-download";
import useTabTitle from '../../utils/useTabTitle'
import { dataMapToBinaryArg, dataMapToMutipleCat } from "./resultPieChart/convertToD3Format";

export default function Result() {
  useTabTitle("ARGNet | Result")
  const { token } = useParams();
  const [dataOut, setDataOut] = React.useState(null);
  const [modelOut, setModelOut] = React.useState("xxxxx");
  const [inputFileOut, setInputFileOut] = React.useState(null);
  const [projectNameOut, setProjectNameOut] = React.useState("Project Name");
  const [statisticsOut, setStatisticsOut] = React.useState(null);
  const [inputFileDataOut, setInputFileDataOut] = React.useState(null);
  const [submitTimeOut, setSubmitTimeOut] = React.useState(null);
  const [costTimeOut, setCostTimeOut] = React.useState(null);
  const [expireDateOut, setExpireDateOut] = React.useState(null);
  const [expireDateButtonDisabled, setExpireDateButtonDisabled] =
    React.useState(false);
  const [expireDateMessage, setExpireDateMessage] = React.useState({
    state: false,
    message: "No Error",
  });
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [dateState, setDateState] = React.useState(new Date());
  const [nullData, setNullData] = React.useState({
    state: false,
    message: "No Error",
  });

  // console.log(`This is Result.js token : ${token}`);
  const handleCilckOpen = () => {
    setOpen(true);
  };
  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const handleInputDownload = () => {
    if (inputFileDataOut) {
      const blob = new Blob([inputFileDataOut], { type: "text/plain" });
      fileDownload(blob, inputFileOut);
    }
  };
  let extendDisplay = true;
  const handleExpireExtension = async () => {
    let nowInMs = Date.now();
    if (!expireDateOut) return;
    let expireDateTimeInMs = Date.parse(expireDateOut);
    if (nowInMs < expireDateTimeInMs) {
      await axios
        .post(`${BACKENDURL}/deeplearning/extend`, {
          token: token,
        })
        .then((res) => {
          // console.log(res);
          if ("message" in res.data) {
            setExpireDateOut(res.data["new expire date"]);
            setExpireDateMessage({
              state: true,
              message: "Successfully updated the expire date",
            });
            setExpireDateButtonDisabled(true);
            extendDisplay = false;
          } else {
            switch (res.data.error) {
              case "Your input token is blanked":
                throw new Error("Your input token is blanked");
              case "Invalid token":
                throw new Error("Invalid token");
              case "Record has already expired.":
                throw new Error("The record has already expired.");
              case "Only can extend the next period":
                throw new Error("You only can extend in the next period.");
              case "Max extend":
                throw new Error("You reach the maximum number of times.");
              case "Unexpected error":
                throw new Error("Unexpected error.");
              default:
                throw new Error("Unexpected error.");
            }
          }
        })
        .catch((e) => {
          setExpireDateMessage({
            state: true,
            message: e.message,
          });
        });
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      await axios
        .post(`${BACKENDURL}/deeplearning/resultFromToken`, {
          token: token,
        })
        .then((res) => {
          if (res.data.message) {
            // console.log(res.data.message);
            const {
              jobResult,
              jobType,
              jobInputFile,
              jobProjectName,
              jobTime,
              jobTimeDelta,
              jobInputFileData,
              jobExpireDate,
              jobStatistics,
            } = res.data.message;
            
            // console.log("Job input File Data", jobInputFileData);
            if (jobResult) {
              const data = JSON.parse(jobResult);
              setDataOut({ message: data });
              // console.log(dataMapToBinaryArg(data));
              // console.log(dataMapToMutipleCat(data));
              setStatisticsOut({binary: dataMapToBinaryArg(data), categories: dataMapToMutipleCat(data)});
            }

            if (jobInputFileData && jobInputFile !== "NA") {
              setInputFileDataOut(jobInputFileData);
              setInputFileOut(jobInputFile);
            }

            if (jobStatistics) {
              const statistics = JSON.parse(jobStatistics);
              // console.log(statistics);
              setStatisticsOut(statistics);
            }

            setModelOut(jobType);
            setProjectNameOut(jobProjectName);
            setSubmitTimeOut(jobTime);
            setCostTimeOut(jobTimeDelta);
            setExpireDateOut(jobExpireDate);
            if (Date.now() > Date.parse(jobExpireDate)) {
              setExpireDateButtonDisabled(true);
            }
          } else {
            // console.log("The job hasnot finished yet.");
            setNullData({ state: true, message: "This job doesn't exist." });
          }
        })
        .catch((err) => {
          // console.log("(Result.js) Run into error block", err);
          setNullData({ state: true, message: "This job doesn't exist" });
        });
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    // console.log(expireDateOut);
  }, [expireDateOut]);

  if (!nullData.state) {
    console.log(statisticsOut);
    return (
      <div>
        <ResultHeader />
        <Grid container>
          <Grid sm={2} xs></Grid>
          <Grid item sm={8} xs={12}>
            <div className="result-container">
              <div
                className="task-information-container"
                style={{
                  borderBottom: "1px lightGrey solid",
                }}
              >
                <div className="time-container">
                  <p>
                    Submitted at{" "}
                    <span>
                      {submitTimeOut
                        ? jobTimeConvertToStr(submitTimeOut)
                        : null}
                    </span>
                  </p>
                  <p>
                    <span>
                      {submitTimeOut
                        ? jobTimeDeltaConvertToStr(
                            costTimeOut,
                            dateState,
                            submitTimeOut
                          )
                        : null}
                    </span>
                    cost
                  </p>
                </div>
                <div className="model-status-container">
                  <h3>Project's Name: {projectNameOut}</h3>
                </div>
                <div className="id-file-result-container">
                  <div className="id-file-coantiner">
                    <p>
                      Model:&nbsp;
                      <span>
                        {["ssaa", "lsaa", "ssnt", "lsnt"].includes(modelOut)
                          ? modelOut
                              .replace(
                                "ssaa",
                                "Amino Acid Short Sequence Model"
                              )
                              .replace("lsaa", "Amino Acid Long Sequnece Model")
                              .replace(
                                "ssnt",
                                "Nucleotide Short Sequence Model"
                              )
                              .replace("lsnt", "Nucleotide Long Sequence Model")
                          : "xxxxx"}
                      </span>
                    </p>
                    {inputFileOut ? (
                      <>
                        <p>
                          Input File:&nbsp;<span>{inputFileOut}</span>
                        </p>
                        <p
                          onClick={handleInputDownload}
                          style={{ color: "#3d5a80" }}
                        >
                          Download InputFile
                        </p>
                      </>
                    ) : null}
                  </div>
                  <div className="result-button-container">
                    <button
                      className="primary-button"
                      onClick={handleCilckOpen}
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
              {statisticsOut ? (
                <PieChartTable
                  binary={statisticsOut.binary}
                  categories={statisticsOut.categories}
                />
              ) : null}
              <DownloadSelectBox
                open={open}
                onClose={handleClose}
                selectedValue={selectedValue}
                data={dataOut}
                model={modelOut}
                inputFile={inputFileOut}
                projectName={projectNameOut}
              />

              <div
                style={{
                  textAlign: "left",
                  margin: "20px 0",
                  color: "#3d5a80",
                  fontSize: "0.875rem",
                }}
              >
                <span>*Here ARG stands for antibiotic resistant gene.</span>
              </div>
              {/* <ResultTable data={data.message} /> */}
              {dataOut ? <ResultTable data={dataOut.message} /> : null}
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
            <div>
            <span>
            {expireDateOut &&
              `Expiry Date: ${jobTimeConvertToStr(expireDateOut)}`}
          </span>
            </div>
              <button
                onClick={handleExpireExtension}
                disabled={expireDateButtonDisabled}
                style={{
                  textDecoration: "underline",
                  color: "#3D5A80",
                  display: extendDisplay == true ? "block" : "none",
                }}
              >
                Extend
              </button>
              <div>
            {expireDateMessage.state && (
              <span style={{ color: "red" }}>{expireDateMessage.message}</span>
            )}
            </div>
            <div style={{ height: "20px" }}></div>

            </div>

            <div style={{ height: "30px" }}></div>
          </Grid>
          <Grid sm={2} xs></Grid>
        </Grid>
        <HandleJson />
      </div>
    );
  } else {
    return (
      <div>
        <ResultHeader />
        <Grid container>
          <Grid sm={2} xs></Grid>
          <Grid item sm={8} xs={12}>
            <div className="result-container">
              <div
                className="task-information-container"
                style={{
                  borderBottom: "1px lightGrey solid",
                }}
              >
                <p>{nullData.message}</p>
              </div>
            </div>
          </Grid>
          <Grid sm={2} xs></Grid>
        </Grid>
      </div>
    );
  }
}
