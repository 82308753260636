export const validation = (data, model) => {
    try {
        // console.log("--------------------1")
        let resultList = [];
        // console.log("--------------------2")
        let sentences = data.split("\n>");
        // console.log("--------------------3")
        // console.log("sentences: ", sentences);
        sentences.forEach((sentence, index) => {
            // console.log("--------------------4")
            if (!(sentence === "")) {
                // console.log("--------------------5")
                let idx = sentence.indexOf("\n");
                // console.log("--------------------6")
                let strippedSequence = sentence.slice(idx + 1).toUpperCase();
                // console.log("--------------------7")
                let resultSequence = strippedSequence.replace(/\n/g, "").replace(/\r/g, "");
                // console.log("--------------------8")
                resultList.push(resultSequence);
                // console.log("--------------------9")
            }
        });
        // console.log("--------------------10")

        // console.log("resultList:", resultList)
        // console.log("model:", model)

        let chars = resultList.join("");
        // console.log("--------------------3")
        if (model === "saa" || model === "laa") {
            for (let i = 0; i< chars.length ; i++) {
                let char = chars.charAt(i)
                if (!"ACDEFGHIKLMNPQRSTVWXYBJZ".includes(char)) {
                    return {state : 0, message : "Error: Invalid character."};
            }
        }
        // console.log("--------------------4")
        } else {
            for (let i=0; i< chars.length ; i++) {
                let char = chars.charAt(i)
                if (!"ATCGNWKSYMR".includes(char)) {
                    return {state : 0, message : "Error: Invalid character."};
                }
            }
        }
        // console.log("--------------------5")

        if (model === "saa") {
            for (let i = 0; i < resultList.length; i++) {
                let sequence = resultList[i]
                if (sequence.length > 50 || sequence.length < 30) {
                    return {state : 0, message : "Warning: The length of sequence should not be larger than 50 and smaller than 30."}
                }
            }
        } else if (model === "laa") {
            for (let i = 0; i < resultList.length; i++) {
                let sequence = resultList[i]
                if (sequence.length <= 50) {
                    return {state : 0, message : "Warning: The length of sequence should not be smaller than or equal to 50."}
                }
            }
        } else if (model === "snt") {
            for (let i = 0; i < resultList.length; i++) {
                let sequence = resultList[i]
                if (sequence.length > 150 || sequence.length < 100) {
                    return {state : 1, message : "Warning: The length of sequence should not be larger than 150 or smaller than 100."}
                }
            }
        } else if (model === "lnt") {
            for (let i = 0; i < resultList.length; i++) {
                let sequence = resultList[i]
                if (sequence.length <= 150) {
                    return {state : 1, message : "Warning: The length of sequence should not be smaller than or equal to 150."}
                }
            }
        }
        // console.log("--------------------6")
        return {state : 2, message : null}
    } catch (e) {
        // console.log(e)
        return {state : 0, message : "Error: some errors happen during the validation process", data : null};
    }
};

