import React from "react";
import Grid from "@material-ui/core/Grid";
import logo from "../logo-light-theme.svg";

export default function ExternalHeader() {
  return (
    <div
      className="header-container"
      style={{
        width: "100%",
        height: "96px",
        // boxShadow: "0 2px 12px rgba(0, 0, 0, 0.06)",
        borderBottom: "lightgrey solid 1px",
      }}
    >
      <Grid container direction="column">
        <Grid item container>
          <Grid item sm={2} xs={2}>
            <div
              className="header-container-left"
              style={{
                height: "96px",
                display: "flex",
                marginRight: "0",
                float: "right",
              }}
            >
              <div
                style={{
                  width: "48px",
                  height: "48px",
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: "40px",
                  float: "right",
                }}
              >
                <div className="logo">
                  <img
                    alt="logo"
                    src={logo}
                    style={{
                      width: "48px",
                      height: "48px",
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item sm={8} xs={8}></Grid>
          <Grid item sm={2} xs={2}></Grid>
        </Grid>
      </Grid>
    </div>
  );
}
