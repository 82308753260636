import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import HeaderWhite from "./headerWhite/HeaderWhite";
import Grid from "@material-ui/core/Grid";
import LocationDropdown from "./FormDropdown1";
import editIcon from "../img/editIcon.svg";
import avatarDefault from "../img/avatarDefault.svg";
import { Link } from "react-router-dom";
import ProfileTable from "./ProfileTable";
import GoogleLogout from "./signin/GoogleLogout";
import { connect } from "react-redux";
import { signOut } from "../actions";
import history from "./history";
import theme from "../Theme";
import mapEmailToDetails from "../utils/mapEmailToDetails";
import getCountriesList from "../utils/getCountriesList";
import * as DisplayedMessages from "./DisplayedMessages";
import generateAccessToken from "../utils/GenerateAccessToken";
import "../styles/Profile.css";
import { BACKENDURL } from "../contants/backendSettings";
import useTabTitle from "../utils/useTabTitle";

const Profile = (props) => {
  useTabTitle("ARGNet | Profile")
  const [locationList, setLocationList] = useState(null);
  const [enableSave, setEnableSave] = useState(false);
  const [locationCode, setLocationCode] = useState("");
  const [locationCountry, setLocationCountry] = useState("");
  const [location, setLocation] = useState(null);
  const [update, setUpdate] = useState(false);
  const [errMessage, setErrMessage] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");

  const onClickHandler = (e) => {
    e.preventDefault();
    let accessAcquiredTime = 0
    const fetchLocation = async (token) => {
      try {
        await fetch(`${BACKENDURL}/account/${props.userId}/changeLocation`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "POST",
          credentials: "include",
          body: JSON.stringify({ location: selectedCountry.toUpperCase() }),
        })
          .then((res) => res.json())
          .then((res) => {
            // console.log(res);
            setLocation(null);
            setEnableSave(false);
            if (res.message === "successfully update.") {
              // console.log(DisplayedMessages.LOCATION_SUCCESS_UPDATED);
              setUpdate(true);
            }
            if ("error" in res) {
              if (res["error"] === "unsuccessfully update.") {
                setErrMessage(true);
                setUpdate(false);
                history.push("/unclearError");
                throw new Error(DisplayedMessages.UNCLEAR_ERROR);
              } else {
                setErrMessage(false);
                throw new Error(DisplayedMessages.USER_NOT_EXISTED);
              }
            }
            if ("detail" in res) {
              if (res.detail === "Given token not valid for any token type") {
                // console.log("run into refresh block in Profile");
                generateAccessToken(
                  props.isSignedIn,
                  props.refreshToken,
                  (responseFromGen) => {
                    fetchLocation(responseFromGen);
                  }
                );
                accessAcquiredTime += 1
                if (accessAcquiredTime >= 3) {
                  throw new Error(DisplayedMessages.EXCEED_MAX_ACCESS_TOKEN_LIMIT)
                }
              }
            }
          });
      } catch (err) {
        if (err.message === "Cannot read property 'toUpperCase' of undefined") {
          // console.log(DisplayedMessages.LOCATION_FETCHING_ERROR);
        } else if (err.message === "Failed to fetch") {
          history.push("/serverError");
          // console.log(DisplayedMessages.CONNECTION_ERROR);
        } else {
          // console.log(err.message);
        }
      }
    };
    fetchLocation(props.token);
  };

  useEffect(() => {
    const getFetchedCountries = async () => {
      try {
        const response = await getCountriesList();
        setLocationList(response.countries);
      } catch (err) {
        // console.log("Profile [useEffect]: run into error block")
        setLocationList([]);
      }
    };

    const getFetchedUser = async () => {
      const userEmail = props.userEmail ? props.userEmail : null;
      try {
        const response = await mapEmailToDetails(userEmail);
        setLocationCode(response.locationCode);
        setLocationCountry(response.locationCountry);
      } catch (error) {}
    };
    getFetchedCountries();
    getFetchedUser();
  }, []);

  let saveBtn =
    enableSave === true ? (
      <button
        className="form-input-btn"
        type="submit"
        form="file-form"
        disabled={false}
        onClick={onClickHandler}
      >
        Save{" "}
      </button>
    ) : (
      <button className="form-disabled-btn" disabled={true}>
        Save{" "}
      </button>
    );

  const capitalize = (word, num) => {
    try {
      word = word.split("_")[num];
      if (word !== undefined || word !== null) {
        return word
          .split("")
          .map((letter, index) =>
            index ? letter.toLowerCase() : letter.toUpperCase()
          )
          .join("");
      }
    } catch (err) {
      return word;
    }
  };
  const setLocationFunction = (propsName = null, value) => {
    setLocation(value);
  };

  if (props.isSignedIn) {
    return (
      <ThemeProvider theme={theme}>
        <div
          className="profile-container"
          style={{
            width: "100%",
          }}
        >
          <HeaderWhite />
          <Grid item container>
            <Grid item sm={2} xs="auto">
              {" "}
            </Grid>{" "}
            <Grid item sm={8} xs={12}>
              <div className="profile-content">
                <form className="file-form">
                  <h1
                    style={{
                      fontSize: "2rem",
                      color: "black",
                      letterSpacing: "1px",
                    }}
                  >
                    MY PROFILE{" "}
                  </h1>{" "}
                  <div
                    className="avatar-container"
                    style={{
                      marginTop: "22px",
                      marginBottom: "60px",
                      borderRadius: "50%",
                    }}
                  >
                    <img alt="avatarDefault" src={avatarDefault} />{" "}
                    <button
                      className="editAvatarBtn"
                      style={{
                        background: "none",
                        border: "none",
                        padding: "0",
                        margin: "-12px",
                      }}
                      disabled={true}
                    >
                      <img alt="editIcon" src={editIcon} />{" "}
                    </button>{" "}
                  </div>{" "}
                  <div className="profile-content-both">
                    <div className="profile-content-left">
                      <label className="form-label"> Location </label>{" "}
                      <div className="profile-dropdown">
                        <LocationDropdown
                          setValueFunction={setLocationFunction}
                          isSignedIn
                          selectedCountry={setSelectedCountry}
                          locationCountry={locationCountry}
                          switchLocation={() => setEnableSave(true)}
                          defaultValue={
                            locationList && props.userLocation
                              ? () => {
                                  const defaultLocation = locationList.find(
                                    (datum) =>
                                      datum.code.toLowerCase() ===
                                      props.userLocation.toLowerCase()
                                  );
                                  if (defaultLocation) {
                                    const code =
                                      defaultLocation.code.toLowerCase();
                                    return {
                                      key: code,
                                      value: code,
                                      code: code,
                                      label: defaultLocation.country,
                                    };
                                  } else {
                                    return null;
                                  }
                                }
                              : null
                          }
                          countries={
                            locationList
                              ? locationList.map((data) => {
                                  const code = data.code.toLowerCase();
                                  return {
                                    key: code,
                                    value: code,
                                    code: code,
                                    label: data.country,
                                  };
                                })
                              : [
                                  {
                                    key: "af",
                                    value: "af",
                                    code: "af",
                                    lebel: "afganistan",
                                  },
                                ]
                          }
                        />{" "}
                      </div>{" "}
                      <div style={{ height: "20px" }}> </div>{" "}
                      <div className="firstName">
                        <label className="form-label"> First Name * </label>{" "}
                        <p
                          style={{
                            color: "grey",
                          }}
                        >
                          {capitalize(props.userName, 0)}{" "}
                        </p>{" "}
                      </div>{" "}
                      <div style={{ height: "30px" }}> </div>{" "}
                      <div className="email">
                        <label className="form-label"> Email * </label>{" "}
                        <p
                          style={{
                            color: "grey",
                          }}
                        >
                          {props.userEmail ? props.userEmail : ""}{" "}
                        </p>{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="profile-content-right">
                      <div style={{ height: "90px" }}> </div>{" "}
                      <div className="email">
                        <label className="form-label"> Last Name * </label>{" "}
                        <p
                          style={{
                            color: "grey",
                          }}
                        >
                          {capitalize(props.userName, 1)}{" "}
                        </p>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  {saveBtn} <br />{" "}
                  {update && (
                    <span> {DisplayedMessages.LOCATION_SUCCESS_UPDATED} </span>
                  )}{" "}
                  {errMessage && (
                    <span style={{ color: "#F87700" }}>
                      {" "}
                      {DisplayedMessages.UNCLEAR_ERROR_PLEASE_TRY_AGAIN}{" "}
                    </span>
                  )}{" "}
                  {tokenExpired && (
                    <span style={{ color: "#F87700" }}>
                      {" "}
                      {DisplayedMessages.TOKEN_EXPIRED}{" "}
                    </span>
                  )}{" "}
                </form>{" "}
                <div
                  className="profile-divider"
                  style={{
                    height: "28px",
                    marginBottom: "45px",
                  }}
                ></div>{" "}
                <div
                  className="profile-divider"
                  style={{
                    height: "48px",
                    marginBottom: "45px",
                  }}
                ></div>{" "}
                <Link to={`/changePassword1`}>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "1rem",
                    }}
                  >
                    <a href="#"> Change Password </a>{" "}
                  </div>{" "}
                </Link>{" "}
                <GoogleLogout userId={props.userId} token={props.token} />{" "}
                <div style={{ marginBottom: "30px" }} />{" "}
              </div>{" "}
            </Grid>{" "}
            <Grid item sm={2} xs="auto">
              {" "}
            </Grid>{" "}
          </Grid>{" "}
        </div>{" "}
      </ThemeProvider>
    );
  } else {
    history.push("/");
    return null;
  }
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userEmail: state.auth.email,
    token: state.auth.token,
    userId: state.auth.Id,
    userName: state.auth.name,
    refreshToken: state.auth.refresh,
    userLocation: state.auth.location,
  };
};
export default connect(mapStateToProps, { signOut })(Profile);
