import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useParams } from "react-router-dom";
import TokenWrong from "./TokenWrong";
import TokenExpired from "./TokenExpired";
import { useForm } from "react-hook-form";
import history from "./history";
import * as DisplayedMessages from "./DisplayedMessages";
import { BACKENDURL } from "../contants/backendSettings";
import useTabTitle from "../utils/useTabTitle";


// provide only access token is ok
export default function ForgetPassword22() {
  useTabTitle("ARGNet | Forget Password")
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const { id, token } = useParams();
  const [shouldFetch, setShouldFetch] = useState(false);
  const [access_token, setAccessToken] = useState("");
  const [userId, setUserId] = useState(-1);

  useEffect(() => {
    const verifiedData = async () => {
      try {
        const response = await fetch(
          `${BACKENDURL}/account/verifyIDandToken/${id}/${token}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            method: "GET",
          }
        ).then((res) => res.json());
        // console.log(response);
        if (response.message === "10") {
          // console.log(response);
          setAccessToken(response.payload);
          setUserId(response.userID);
          setShouldFetch("10");
        } else if (response.message === "11") {
          setShouldFetch("11");
        } else if (response.message === "00") {
          setShouldFetch("00");
        } else if (response.message === "01") {
          setShouldFetch("01");
        } else {
          setShouldFetch("xx");
        }
      } catch (err) {
        if (err.message === "Failed to fetch") {
          history.push("/serverError");
          // console.log(DisplayedMessages.CONNECTION_ERROR);
        } else {
          // console.log(err.message);
        }
      }
    };
    verifiedData();
  }, []);

  const onSubmit = (data, e) => {
    e.target.reset();
    e.preventDefault();
    // console.log(data);
    const fetchChangedPassword = async () => {
      try {
        await fetch(`${BACKENDURL}/account/verifyIDandToken/${id}/${token}`, {
          headers: {
            "Content-Type": "application/json",
          },
          method: "DELETE",
        })
          .then((res) => res.json())
          .then((res) => {
            // console.log(res);
          })
          .catch((err) => {
            // console.log(err)
          });
        await fetch(`${BACKENDURL}/account/${userId}/changePassword`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          method: "POST",
          body: JSON.stringify({
            new_password: data.password,
            confirm_new_password: data.password2,
          }),
        })
          .then((res) => res.json())
          .then((response) => {
            // console.log(response);
            if (response === 1) {
              // console.log(DisplayedMessages.SUCCESS_UPDATED);
              history.push("/forgetPassword3");
            } else if (
              response.detail === "Given token not valid for any token type"
            ) {
              setError("password", {
                type: "matches",
                message:
                  DisplayedMessages.REFRESH_TOKEN_EXPIRED_FORGET_PASSWORD,
              });
              setError("password2", {
                type: "matches",
                message:
                  DisplayedMessages.REFRESH_TOKEN_EXPIRED_FORGET_PASSWORD,
              });
              throw new Error(DisplayedMessages.TOKEN_EXPIRED);
            } else {
              history.push("/unclearError");
              throw new Error(DisplayedMessages.UNCLEAR_ERROR);
            }
          });
      } catch (err) {
        if (err.message === "Failed to fetch") {
          // console.log(DisplayedMessages.CONNECTION_ERROR);
          history.push("/serverError");
        } else {
          // console.log(err.message);
        }
      }
    };

    if (data.password !== data.password2) {
      setError("password", {
        type: "matches",
        message: DisplayedMessages.PASSWORD_MATCHES,
      });
      setError("password2", {
        type: "matches",
        message: DisplayedMessages.PASSWORD_MATCHES,
      });
      // console.log(DisplayedMessages.PASSWORD_MATCHES);
    } else {
      fetchChangedPassword();
    }
  };

  if (shouldFetch === "10") {
    return (
      <div className="reset-password-container">
        <Grid item container>
          <Grid item sm={2} xs="auto"></Grid>
          <Grid item sm={8} xs={12}>
            <div
              className="rspw-form-container"
              style={{
                marginTop: "10%",
                borderRadius: "2px",
                padding: "20px",
                width: "70%",
                height: "400px",
                marginLeft: "auto",
                marginRight: "auto",
                boxShadow: "0px 2px 24px rgba(0, 0, 0, 0.06)",
              }}
            >
              <h1
                style={{
                  marginTop: "36px",
                  fontSize: "2rem",
                  color: "black",
                  letterSpacing: "1px",
                  textAlign: "center",
                  marginBottom: "40px",
                }}
              >
                Set New Password
              </h1>

              <form
                onSubmit={handleSubmit(onSubmit)}
                className="form-inputs"
                id="forget-form"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <label className="form-label"> Password </label>
                <input
                  className="form-input"
                  type="password"
                  placeholder="Enter your password"
                  style={{
                    textAlign: "center",
                    padding: "0",
                    width: "80%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  {...register("password", {
                    required: true,
                    maxLength: 20,
                    minLength: 8,
                    validate: (value) => {
                      if (value.toLowerCase() === value) {
                        return false;
                      }
                      if (!/(?=.*[#?!@$%^&*-.])/.test(value)) {
                        // if (!/(?=.*[!@#$%^&*])/.test(value)) {
                          return false;
                        }
                      return true;
                    },
                  })}
                />
                {errors.password?.type === "required" && (
                  <span style={{ color: "#F87700" }}>
                    {DisplayedMessages.REQUIRED}
                  </span>
                )}
                {errors.password?.type === "maxLength" && (
                  <span style={{ color: "#F87700" }}>
                    {DisplayedMessages.PASSWORD_ATMOST_20}
                  </span>
                )}
                {errors.password?.type === "minLength" && (
                  <span style={{ color: "#F87700" }}>
                    {DisplayedMessages.PASSWORD_ATLEAST_8}
                  </span>
                )}
                {errors.password?.type === "validate" && (
                  <span style={{ color: "#F87700" }}>
                    {DisplayedMessages.PASSWORD_COMBIN}
                  </span>
                )}
                {errors.password && (
                  <span style={{ color: "#F87700" }}>
                    {errors.password.message}
                  </span>
                )}
                <div
                  style={{
                    height: "20px",
                  }}
                ></div>
                <label className="form-label"> Confirm Password </label>
                <input
                  className="form-input"
                  type="password"
                  placeholder="Confirm your password"
                  style={{
                    textAlign: "center",
                    padding: "0",
                    width: "80%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  {...register("password2", {
                    required: true,
                    maxLength: 20,
                    minLength: 8,
                    validate: (value) => {
                      if (value.toLowerCase() === value) {
                        return false;
                      }
                      if (!/(?=.*[#?!@$%^&*-.])/.test(value)) {
                        // if (!/(?=.*[!@#$%^&*])/.test(value)) {
                          return false;
                        }
                      return true;
                    },
                  })}
                />
                {errors.password2?.type === "required" && (
                  <span style={{ color: "#F87700" }}>
                    {DisplayedMessages.REQUIRED}
                  </span>
                )}
                {errors.password2?.type === "maxLength" && (
                  <span style={{ color: "#F87700" }}>
                    {DisplayedMessages.PASSWORD_ATMOST_20}
                  </span>
                )}
                {errors.password2?.type === "minLength" && (
                  <span style={{ color: "#F87700" }}>
                    {DisplayedMessages.PASSWORD_ATLEAST_8}
                  </span>
                )}
                {errors.password2?.type === "validate" && (
                  <span style={{ color: "#F87700" }}>
                    {DisplayedMessages.PASSWORD_COMBIN}
                  </span>
                )}
                {errors.password2 && (
                  <span style={{ color: "#F87700" }}>
                    {errors.password2.message}
                  </span>
                )}
                <br />
                <button
                  form="forget-form"
                  className="form-input-btn"
                  type="submit"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "40px",
                  }}
                >
                  Confirm
                </button>
              </form>
            </div>
          </Grid>
          <Grid item sm={2} xs="auto"></Grid>
        </Grid>
      </div>
    );
  } else if (shouldFetch === "00" || shouldFetch === "xx") {
    return <TokenWrong />;
  } else {
    return <TokenExpired />;
  }
}
