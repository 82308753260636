export const dataMapToBinaryArg = (data) => {
    const argFilteredNum = data.filter((item) => item.ARG_prediction === "ARG").length
    const nonArgFilteredNum = data.length - argFilteredNum
    return [{
        label: "Arg",
        value: argFilteredNum
    }, {
        label: 'Non-arg',
        value: nonArgFilteredNum
    }]
}

export const dataMapToMutipleCat = (data) => {
        let obj = {}
        let resultList = []
        data.forEach((item) => {
            let resistance = item.resistance_category
            if (resistance) {
                if (!(resistance in obj)) {
                    obj[resistance] = 1
                } else {
                    obj[resistance] +=1
                }
            }
        })
        for (let [key, value] of Object.entries(obj)) {
            resultList.push({
                label : key,
                value
            })
        }
        return resultList;

}




