import { createTheme } from "@material-ui/core/styles";
import PTRootUIRegularWoff2 from "./font/PT/PT-Root-UI_Regular.woff2";
import PTRootUIMediumWoff2 from "./font/PT/PT-Root-UI_Medium.woff2";

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
const PTRootUIRegular = {
    fontFamily: "PTRootUIRegular",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 400,
    src: `
    local('PTRootUIRegular'),
    local('PT-Root-UI_Regular'),
    url(${PTRootUIRegularWoff2}) format('woff2')
  `,
    unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const PTRootUIMedium = {
    fontFamily: "PTRootUIMedium",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 400,
    src: `
    local('PTRootUIMedium'),
    local('PT-Root-UI_Medium'),
    url(${PTRootUIMediumWoff2}) format('woff2')
  `,
    unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const theme = createTheme({
    typography: {
        fontFamily: PTRootUIRegular,
        textDecoration: "none",
    },

    palette: {
        primary: {
            main: "#3D5A80",
        },
        secondary: {
            main: "#F87700",
        },
        textPrimary: {
            main: "#000000",
        },
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                "@font-face": [PTRootUIRegular],
            },
        },
        MuiOutlinedInput: {
            root: {
                border: "lightGrey 1px solid",
                borderRadius: "2px",
                color: "grey",
                fontSize: "1rem",
            },
            notchedOutline: {
                border: "none",
            },
        },
        MuiFormControlLabel: {
            root: {
                marginRight: "0",
            },
        },
        MuiInputBase: {
            input: {
                padding: "none",
            },
        },
        MuiAutocomplete: {
            inputRoot: {
                paddingLeft: "0",
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: "none",
            },
        },
        MuiAutocomplete: {
            paper: {
                backgroundColor: "white",
            },
        },
        MuiStepper: {
            root: {
                padding: "none",
            },
        },
        MuiStepIcon: {
            active: {
                color: "#3D5A80",
            },
        },
        // MuiSvgIcon: {
        //     root: {
        //         color: "lightGrey",
        //     },
        // },
        MuiAccordionSummary: {
            root: {
                padding: "0",
            },
        },
        MuiAccordion: {
            root: {
                boxShadow: "none",
                backgroundColor: "none",
            },
        },
        MuiButton: {
            root: {
                padding: "0",
                height: "30px",
            },
        },
        MuiButtonBase: {
            defaultProps: {
                // The props to change the default for.
                disableRipple: true, // No more ripple!
            },
        },
    },
});
export default theme;