import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const ArgPieChart = (props) => {
  const ref = useRef(null);
  const createPie = d3.pie().value((d) => d.value);
  const createArcArg = d3
    .arc()
    .innerRadius(props.innerRadius)
    .outerRadius(props.outerRadius);
  const mouseArc = d3
    .arc()
    .innerRadius(props.innerRadius)
    .outerRadius(props.outerRadius);

  const colors = d3.scaleOrdinal(props.colorScale);
  const width = props.width;
  const height = props.height;

  useEffect(() => {
    drawChart();
  });
  var isClicked = false;
  const drawChart = () => {
    function unClick() {
      d3.selectAll(".createArcArg")
        .style("fill-opacity", 1)
        .transition()
        .duration(600);
      d3.selectAll(".legendArg")
        .style("fill-opacity", 1)
        .transition()
        .duration(600);
    }

    function hoverClick(d) {
      d3.selectAll(".createArcArg")
        .style("fill-opacity", 0.3)
        .transition()
        .duration(600);
      d3.selectAll(".legendArg")
        .style("fill-opacity", 0.3)
        .transition()
        .duration(600);
    }

    // function showLegend(d) {
    //   d3.selectAll("#" + d.id + "-legendArg").style("dispaly", "block");
    //   console.log();
    // }

    const data = createPie(props.data);

    const svg = d3
      .select(".arg-pie")
      //   .append("svg")
      .attr("viewBox", `${-width / 2} ${-width / 2} ${width} ${width}`);
    //   .style("width", `${width}`)
    //   .style("height", `${height}`);
    svg.selectAll("*").remove();

    const svgLegendArg = d3.select(".arg-legendArg");
    //   .attr("viewBox", "-100 -100 400 400")
    svgLegendArg.selectAll("*").remove();

    const legendArg = svgLegendArg
      .append("svg")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("text-anchor", "start")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "legendArg")
      .sort((a, b) => b.value - a.value)
      .attr("id", (d) => {
        return d.data.label + "-legendArg";
      })
      .on("mouseout", function () {
        if (!isClicked) {
          unClick();
        }
      })
      .on("mouseover", function (d) {
        if (!isClicked) {
          hoverClick(d);
          d3.select(this).style("fill-opacity", "1").transition().duration(600);
          d3.selectAll("#" + this.id.replace("-legendArg", ""))
            .style("fill-opacity", "1")
            .transition()
            .duration(600);
        }
      })
      .on("click", function (event, d) {
        isClicked = true;
        hoverClick(d);
        d3.select(this).style("fill-opacity", "1").transition().duration(600);
        d3.selectAll("#" + this.id.replace("-legendArg", ""))
          .style("fill-opacity", "1")
          .transition()
          .duration(600);
        event.stopPropagation();
        // console.log(d);
      });

    //   .style("display", "none");
    legendArg
      .append("rect")
      .attr("width", 19)
      .attr("height", 19)
      .attr("fill", (d, i) => colors(i))
      .attr("transform", function (d, i) {
        return "translate(0," + i * 20 + ")";
      });
    legendArg
      .append("text")
      .attr("x", 23)
      .attr("y", 9)
      .attr("dy", "0.32em")
      .text((d) => d.data.label)
      .attr("transform", function (d, i) {
        return "translate(0," + i * 20 + ")";
      });

    const path = svg
      .append("g")
      .selectAll("path")
      .data(data)
      .join("path")
      .sort((a, b) => b.value - a.value)
      .attr("fill", (i) => colors(i))
      .attr("stroke", "white")
      .attr("stroke-width", "1.5px")
      .attr("d", createArcArg)
      .attr("class", "createArcArg")
      .attr("id", (d) => d.data.label)
      .on("mouseout", function () {
        if (!isClicked) {
          unClick();
        }
      })
      .on("mouseover", function (d) {
        if (!isClicked) {
          hoverClick(d);
          //   d3.selectAll("#" + d.id + "-legendArg").style("dispaly", "block");
          d3.select(this).style("fill-opacity", "1").transition().duration(600);
          d3.selectAll("#" + this.id + "-legendArg")
            .style("fill-opacity", "1")
            .transition()
            .duration(600);
        }
      })
      .on("click", function (event, d) {
        isClicked = true;
        hoverClick(d);
        d3.select(this).style("fill-opacity", "1").transition().duration(600);
        d3.selectAll("#" + this.id + "-legendArg")
          .style("fill-opacity", "1")
          .transition()
          .duration(600);
        event.stopPropagation();
        // console.log(d);
      });
  };

  return (
    <div className="pie-chart">
      <svg className="arg-pie" width={width} height={height} />
      <svg className="arg-legendArg" width={width / 2} height={height} />
    </div>
  );
};

export default ArgPieChart;
