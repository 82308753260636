const laaSample = `>CCP45025.1|FEATURES|hmdarg|isoniazid|kasA
GARAGVMTPVSACSSGSEAIAHAWRQIVMGDADVAVCGGVEGPIEALPIAAFSMMRAMSTRNDEPERASRPFDKDRDGFVFGEAGALMLIETEEHAKARGAKPLARLLGAGITSDAFHMVAPAADGVRAGRAMTRSLELAGLSPADIDHVNAHGTATPIGDAAEANAIRVAGCDQAAVYAPKSALGHSIGAVGALESVLTVLTLRDGVIPPTLNYETPDPEIDLDVVAGEPRYGDYRYAVNNSFGFGGHN
>gi:505065763:ref:WP_015252865.1:|FEATURES|deeparg|tunicamycin|tmrB
GSFGSGKTQTAFELHRRLNPSYVYDPEKMGFALRSMVPQEIAKDDFQSYPLWRAFNYSLLASLTDTYRGIIIVPMTIVYPEYFNEIIGRLRQEGRIVHHFTLMASKETLLKRLRTRAE`
const saaSample = `>gi:505065763:ref:WP_015252865.1:|FEATURES|deeparg|tunicamycin|tmrB_0
RGIIIVPMTIVYPEYFNEIIGRLRQEGRIV
>AGQ48857.1|FEATURES|hmdarg|pleuromutilin|eatAv_0
GNFSIYEEQKKLRDEFEMAQNEKLKKEVSR`
const lntSample = `>S60108|FEATURES|resfinder|aminoglycoside|kgmB
AGAGCGTCGCGCCCGAAACCGTGCGCCGGCTGGCCACGAGCGCCCTGGTGGCCAGCCGCGGCGACCTCGCGGAGGCGGTCAAGCGCACCAAGCGCGGGCTGCACGAGATCTTCGGCGCCTACCTGCCCAGCCCGCCCAAGTACGACGCCCTCCTCCGCCAGCTCAGGGGGGCGGTCGACGCGGCGACGACGAGGCCGTGCGGGCACCCTGCACCGCGCCATGTCCACGCACGCCTCCACCCGCGAGCGCTGCCCATCCTCGACGAGTTCTACCGCGAGGTCTTCGCCCGGTGCGCCGACCCGGCCAGCGTGCGTGACCTGGCCTGCGGGATGAACCCGCTCGCCGCGCCGTGGATGCCCGGCTCGGACGCGTTCACCTACCACGCGTCCGACATCGACACCCGGCTCATGGAGTTCCTCGACGCCGCCCTGGAGACGCTCGGGGTCGCGCACGACGTCCGGGTGCGCGACCTGATGACCGGGGTCGGCGAGGTCGAGACCGACGTG
>AJ635405|FEATURES|resfinder|beta-lactam|blaLEN9
GGATGGTGGAAATGGATCTGGCCAGCGGCCGCACGCTGGCCGCCTGGCGCGCCGATGAACGCTTTCCCATGGTGAGCACCTTTAAAGTGCTGCTGTGCGGCGCGGTGCTGGCGCGGGTGGATGCCGGGCTCGAACAACTGGATCGGCGGATCCACTACCGCCAGCAGGATCTGGTGGACTACTCCCCGGTCAGCGAAAAACACCTTGTCGACGGGATGACGATCGGCGAACTCTGTGCCGCCGCCATCACCCTGAGCGATAACAGCGCTGGCAATCTGCTGCTGGCCACCGTCGGCGGCCCCGCGGGATTAACTGCCTTTCTGCGCCAGATCGGTGACAACGTCACCCGTCTTGACCGCTGGGAAACGGCACTGAATGAGGCGCTTCCCGGCGACGCGCGCGACACCACCACCCCGGCCAGCATGGCCGCCACGCTGCGCAAACTACTGACCGCGCAGCATCTGAGCGCCCGT`
const sntSample = `>S60108|FEATURES|resfinder|aminoglycoside|kgmB_0
CCGCACCCGGCTCCCGGACCCGGCGATCCCGAGGACCCGAGGCTGGCGGAGGTCGTCGACGCGGTCCGGTCCAGCAGGCGCTACCAGAGCGTCGCGCCCG
>APOK01000044|FEATURES|resfinder|beta-lactam|blaOXA-290_0
ACATATGATGGGCAAACATTTCAAGAATATGGCAATGCGTTGAGTCGATCGAATACGGCTTATATTCCAGCCTCAACCTTCAAGATGTTAAATGCTCTGA`

export { laaSample, saaSample, lntSample, sntSample }