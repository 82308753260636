import { React, useState } from "react";
import Grid from "@material-ui/core/Grid";
import "./signin/FormSignin.css";
import HeaderWhite from "./headerWhite/HeaderWhite";
import { Link, Redirect } from "react-router-dom";
import useTabTitle from "../utils/useTabTitle";

const ChangePassword3 = () => {
  useTabTitle("ARGNet | Change Password")
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [second, setSecond] = useState(5);
  const fiveSecondRedirect = setInterval(() => {
    setSecond(second - 1);
    if (second === 0) {
      setShouldRedirect(true);
      clearInterval(fiveSecondRedirect);
    }
  }, 1000);
  if (shouldRedirect) return <Redirect to="/profile" />;
  return (
    <div
      className="ChangePassword3-container1"
      style={{
        height: "100vh",
        width: "100%",
      }}
    >
      <HeaderWhite />
      <Grid item container>
        <Grid item sm={2} xs="auto"></Grid>
        <Grid item sm={8} xs={12}>
          <div
            className="fgpw-form-container"
            style={{
              textAlign: "left",
              paddingTop: "6%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <h1
              style={{
                color: "black",
                fontSize: "2rem",
                letterSpacing: "1px",
                paddingBottom: "4px",
              }}
            >
              Your Password Has Successfully Changed!
            </h1>
            <p>You can go back to ARGNet without login again</p>
            <Link
              to={`/profile`}
              style={{
                display: "block",
                color: "#3D5A80",
                paddingTop: "60px",
                fontSize: "1rem",
              }}
              href="#"
            >
              &lt; Go to profile
            </Link>
            <p
              style={{
                color: "grey",
              }}
            >
              The page will jump after {second} second.
            </p>
            <div
              className="divider"
              style={{
                width: "100%",
                borderBottom: "lightgrey solid 1px",
                paddingTop: "30px",
              }}
            ></div>
          </div>
        </Grid>
        <Grid item sm={2} xs="auto"></Grid>
      </Grid>
    </div>
  );
};

export default ChangePassword3;
