import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import json from "./prediction.json";
import { StyledEngineProvider } from "@mui/styled-engine";
import { laaSample, saaSample, lntSample, sntSample } from "./sampleFiles/sample";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function EnterSequence({value , inputSequence, setInputSequence, model}) {
  const data = json.data;
  const defaultReq = json.defaultReq;
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleChange = (e) => {
    if (e.target.value ===  "") {
      setInputSequence("")
    } else {
      setInputSequence(e.target.value);
    }
  };

  const autofill = () => {
    switch (model) {
      case 'saa':
        setInputSequence(saaSample)
        break
      case 'laa':
        setInputSequence(laaSample)
        break
      case 'snt':
        setInputSequence(sntSample)
        break
      case 'lnt':
        setInputSequence(lntSample)
        break
      default:
        console.error(`invalide model: ${model}`)
    }

  }
  // console.log(`EnterSequence.js (inputSequence): `, inputSequence);
  // props.inputSequence(inputSequence);
  return (
    <div className="prediction-sequence">
      <h1>Enter the sequence</h1>
      <div className="button-container">
        <button onClick={autofill}>Sample Sequence</button>
        {/*&nbsp;&nbsp;|&nbsp;&nbsp;
  <button onClick={handleClickOpen1}>Sequence Requirements</button>*/}
        <textarea
          rows="5"
          id="sequence-input"
          onChange={handleChange}
          style={{
            height: "100px",
            resize: "none",
            paddingTop: "6px",
            width: "99%",
          }}
          name="sequenceInput"
          value={inputSequence}
        ></textarea>
      </div>
      <StyledEngineProvider injectFirst>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Sample Sequence
          </BootstrapDialogTitle>
          <DialogContent dividers>
            {data.map((datum) => {
              return (
                <div>
                  <p style={{fontWeight : 'bold'}}>{datum.name}</p>
                  <p style={{fontWeight : 600}}>Non-ARG:</p>
                  <p>{datum.sampleSequence1}</p>
                  <p style={{fontWeight : 600}}>ARG:</p>
                  <p>{datum.sampleSequence2}</p>
                  <br />
                </div>
              )
            })}
          </DialogContent>
        </BootstrapDialog>
        <BootstrapDialog
          onClose={handleClose1}
          aria-labelledby="customized-dialog-title"
          open={open1}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose1}
          >
            Sequence Requirements
          </BootstrapDialogTitle>
          <DialogContent dividers>
          {data.map((datum) => {
            return (
              <div>
                <p style={{fontWeight : 'bold'}}>{datum.name}</p>
                <p>{datum.requirements}</p>
                <br />
              </div>
            )
          })}
          <p>{defaultReq}</p>
          </DialogContent>
        </BootstrapDialog>
      </StyledEngineProvider>
    </div>
  );
}
