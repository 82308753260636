// external libraries
import React, { useState, useEffect } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";

// custom components
import HeaderWhite from "../headerWhite/HeaderWhite";
import Footer from "../Footer";

// constant data
import * as DisplayedMessages from "../DisplayedMessages";
import { CAPTCHA_SITE_KEY } from "../../contants/keys";
import { BACKENDURL } from "../../contants/backendSettings";

// css
import "../../styles/ContactUs.css";
import { connect } from "react-redux";

import useTabTitle from "../../utils/useTabTitle";

// Input keys for react hook form
const FORM_KEY_FIRST_NAME = "formKeyFirstName";
const FORM_KEY_LAST_NAME = "formKeyLastName";
const FORM_KEY_EMAIL = "formKeyEmail";
const FORM_KEY_MESSAGE = "formKeyMessage";
const FORM_KEY_CAPTCHA_TOKEN = "formCaptchaToken";

// Input validation rules for react hook form
const FORM_VALIDATION_FIRST_NAME = {
  required: true,
  maxLength: 800,
};
const FORM_VALIDATION_LAST_NAME = {
  required: true,
  maxLength: 800,
};
const FORM_VALIDATION_EMAIL = {
  required: true,
  // email validation is handled by HTML
  maxLength: 345,
};
const FORM_VALIDATION_MESSAGE = {
  required: true,
  maxLength: 8000,
};
const FORM_VALIDATION_CAPTCHA = {
  required: true,
};

const ContactUs = ({ isSignedIn, userEmail, userName }) => {
  useTabTitle("ARGNet | Contact Us")
  // API control
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const onSubmit = (data, e) => {
    setIsSubmitting(true);
    // console.log(data);
    fetch(`${BACKENDURL}/addition/contactUs`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({
        first_name: data.formKeyFirstName,
        last_name: data.formKeyLastName,
        email: data.formKeyEmail,
        message: data.formKeyMessage,
        frontendToken: data.formCaptchaToken,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // TODO: add success API handling
          // console.log(result);
          if ("message" in result) {
            onSubmitSuccess();
          } else {
            let errorMsg = result.error
            onSubmitFailure(errorMsg);
          }
        },
        (error) => {
          onSubmitError(error.message);
        }
      )
      .then(() => {
        setIsSubmitting(false);
      });
  };
  const onSubmitSuccess = () => {
    history.push("/feedbackSuccess");
  };
  const onSubmitFailure = (msg) => {
    // TODO: output failure by backend message accordingly
    history.push("/unclearError");
  };
  const onSubmitError = (errorMessage) => {
    history.push("/unclearError");
  };

  // message character counter
  const [msgCharCount, setMsgCharCount] = useState(0);
  const onMsgChange = (e) => {
    setMsgCharCount(e.target.value.length);
  };

  // validation with react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const renderFirstNameError = () => {
    switch (errors[FORM_KEY_FIRST_NAME]?.type) {
      case "required":
        return <span>{DisplayedMessages.REQUIRED}</span>;
      case "maxLength":
        return <span>{DisplayedMessages.EXCEED_MAX_CHAR_LIMIT}</span>;
      default:
        return null;
    }
  };
  const renderLastNameError = () => {
    switch (errors[FORM_KEY_LAST_NAME]?.type) {
      case "required":
        return <span>{DisplayedMessages.REQUIRED}</span>;
      case "maxLength":
        return <span>{DisplayedMessages.EXCEED_MAX_CHAR_LIMIT}</span>;
      default:
        return null;
    }
  };
  const renderEmailError = () => {
    switch (errors[FORM_KEY_EMAIL]?.type) {
      case "required":
        return <span>{DisplayedMessages.REQUIRED}</span>;
      case "maxLength":
        return <span>{DisplayedMessages.EXCEED_MAX_CHAR_LIMIT}</span>;
      default:
        return null;
    }
  };
  const renderMessageError = () => {
    switch (errors[FORM_KEY_MESSAGE]?.type) {
      case "required":
        return <span>{DisplayedMessages.REQUIRED}</span>;
      case "maxLength":
        return <span>{DisplayedMessages.EXCEED_MAX_CHAR_LIMIT}</span>;
      default:
        return null;
    }
  };
  const renderCaptchaError = () => {
    switch (errors[FORM_KEY_CAPTCHA_TOKEN]?.type) {
      case "required":
        return <span>{DisplayedMessages.REQUIRED}</span>;
      default:
        return null;
    }
  };

  // captcha init + token handling + validation
  useEffect(() => {
    register(FORM_KEY_CAPTCHA_TOKEN, FORM_VALIDATION_CAPTCHA);
  }, [register]);
  const onReceiveToken = (token) => {
    // console.log("token received:" + token);
    setValue(FORM_KEY_CAPTCHA_TOKEN, token, { shouldValidate: true });
  };

  // using user data as default value
  const [inputFirstName, setInputFirstName] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  useEffect(() => {
    if (isSignedIn) {
      setInputFirstName(userName);
      setInputEmail(userEmail);
    }
  }, [isSignedIn, userEmail, userName]);

  return (
    <div>
      <div className="external-pages-container">
        <HeaderWhite />
        <Grid
          item
          container
          style={{
            marginBottom: "36px",
          }}
        >
          <Grid item md={2} sm={2} xs></Grid>
          <Grid item md={4} sm={8} xs={12}>
            <div className="external-pages">
              <div className="text-container-m">
                <h2>SURPPOT</h2>
                <h1>
                  Get in <span>Touch</span> with Us
                </h1>
                <div className="text-button-container">
                  <p>
                    {/*Before submitting your question, please visit
        <Link to={`/faq`}> FAQ</Link>.*/}
                    <br /> <br />
                    If you cannot find an answer to your question or would like
                    to submit a suggestion, feel free to drop us a line using
                    the form on the right and we will get back to you shortly.
                    We are always open to new creative ideas to improve our
                    platform.
                  </p>
                </div>
              </div>
              <div
                className="external-page-divider"
                style={{
                  marginBottom: "36px",
                }}
              />
              {/*<Link to={`/faq`}>Solve by yourself ? See FAQ {">"}</Link>*/}
            </div>
          </Grid>
          <Grid item md sm={2} xs></Grid>
          <Grid item md sm={2} xs></Grid>
          <Grid item md={4} sm={8} xs={12}>
            <div className="external-pages">
              <div className="contact-form-container">
                <h5>Leave Your Thoughts...</h5>
                <form
                  id="contact-form"
                  onSubmit={handleSubmit(onSubmit)}
                  // onSubmit={this.handleSubmit.bind(this)}
                  //method="POST"
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      className="form-group"
                      style={{
                        width: "45%",
                        height: "72px",
                      }}
                    >
                      <label htmlFor="name">First Name</label>
                      <br />
                      <input
                        type="text"
                        className="form-input"
                        placeholder="First name"
                        style={{
                          paddingLeft: "4.4%",
                        }}
                        onInput={(e) => setInputFirstName(e.target.value)}
                        value={inputFirstName}
                        {...register(
                          FORM_KEY_FIRST_NAME,
                          FORM_VALIDATION_FIRST_NAME
                        )}
                      />
                      {renderFirstNameError()}
                    </div>
                    <div
                      className="form-group"
                      style={{
                        width: "45%",
                        height: "72px",
                      }}
                    >
                      <label htmlFor="name">Last Name</label>
                      <br />
                      <input
                        type="text"
                        className="form-input"
                        placeholder="Last name"
                        style={{
                          paddingLeft: "4.4%",
                        }}
                        {...register(
                          FORM_KEY_LAST_NAME,
                          FORM_VALIDATION_LAST_NAME
                        )}
                      />
                      {renderLastNameError()}
                    </div>
                  </div>
                  <div
                    className="form-group"
                    style={{
                      height: "72px",
                    }}
                  >
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <br />
                    <input
                      type="email"
                      className="form-input"
                      aria-describedby="emailHelp"
                      placeholder="Email address"
                      style={{
                        width: "99%",
                      }}
                      onInput={(e) => setInputEmail(e.target.value)}
                      value={inputEmail}
                      {...register(FORM_KEY_EMAIL, FORM_VALIDATION_EMAIL)}
                    />
                    {renderEmailError()}
                  </div>
                  <div
                    className="form-group"
                    style={{
                      height: "140px",
                    }}
                  >
                    <label htmlFor="message">Message</label>
                    <br />
                    <textarea
                      placeholder="Leave your message here..."
                      className="form-input"
                      rows="5"
                      maxLength={FORM_VALIDATION_MESSAGE.maxLength}
                      style={{
                        height: "100px",
                        resize: "none",
                        paddingTop: "6px",
                        width: "99%",
                      }}
                      onInput={onMsgChange}
                      {...register(FORM_KEY_MESSAGE, FORM_VALIDATION_MESSAGE)}
                    ></textarea>
                    <div className="char-counter">
                      <div>{renderMessageError()}</div>
                      <div
                        style={{
                          color: "grey",
                          fontSize: "0.75rem",
                        }}
                      >
                        {msgCharCount}/{FORM_VALIDATION_MESSAGE.maxLength}
                      </div>
                    </div>
                  </div>
                  {
                    // Testing purpose only
                    // TODO: delete after tested
                    // <button
                    //   type="button"
                    //   onClick={() => {
                    //     onReceiveToken("abc");
                    //   }}
                    // >
                    //   for testing purpose: update captcha token
                    // </button>
                  }
                  <div
                    className="form-group captcha-container"
                    style={{
                      height: "80px",
                    }}
                  >
                    <ReCAPTCHA
                      sitekey={CAPTCHA_SITE_KEY}
                      onChange={onReceiveToken}
                    />
                    {renderCaptchaError()}
                  </div>
                  <button
                    type="submit"
                    className="form-input-btn submit-button"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <CircularProgress
                        size={24}
                        className="spinner"
                        color="#c4c4c4"
                        thickness={5}
                      />
                    ) : (
                      "Send"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </Grid>
          <Grid item md={2} sm={2} xs></Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isSignedIn: state.auth.isSignedIn,
  userEmail: state.auth.email,
  userName: state.auth.name,
});

export default connect(mapStateToProps, {})(ContactUs);
