import React from "react";
import HeaderWhite from "../headerWhite/HeaderWhite";
import { Grid, Divider } from "@material-ui/core";
import Footer from "../Footer";
import OurTeamContent from "./OurTeamContent";
import memberInfo from "./memberInfo.json";
import useTabTitle from '../../utils/useTabTitle'

function OurTeam() {
  useTabTitle("ARGNet | Our Team")
  return (
    <div className="external-pages-container">
      <HeaderWhite />
      <Grid container>
        <Grid item sm xs></Grid>
        <Grid item sm={8} xs={12}>
          <div className="external-pages">
            <h2>OUR TEAM</h2>
            <h1>
              Get to Know the <span>Team</span> Behind <span>ARG</span>Net.
            </h1>
            {/* <div className="text-button-container">
              <div className="text-container-l">
                <p>
                  You have completed all the steps of registration, you are
                  welcome! You have completed all the steps of registration, you
                  are welcome!You have completed all the steps of registration,
                  you are welcome! You have completed all the steps of
                  registration, you are welcome!
                </p>
              </div>
            </div> */}
            <Divider
              style={{
                marginTop: "36px",
              }}
            />
            <div className="our-team-content-container">
              {memberInfo.data.map((a) => (
                <OurTeamContent info={a} />
              ))}
            </div>
          </div>
        </Grid>
        <Grid item sm xs></Grid>
      </Grid>
      <Footer />
    </div>
  );
}

export default OurTeam;
